.header {
    position: relative;
    z-index: 999;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.14);
    .header-top {
        background-color: #f5f5f5;
        padding: 2px 0;
    }
    &.is-sticky {
        background: $white;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
        -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
    }
}

.header-top li {
    list-style-type: none;
    margin-left: 25px;
}

.header-top-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.header-top-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header-middle {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 5px 0;
    .header-logo {
        display: inline-block;
        vertical-align: middle;
        img {
            max-height: 40px;
        }
    }
}

.header-searchbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    background: $white;
    border-radius: 7px;
    flex-grow: 1;
    height: 40px;
    input {
        background: #f8f8f8;
        border: 0;
        padding-right: 50px;
        border: 1px solid #ddd;
        &::-webkit-input-placeholder,
        &:-moz-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder {
            color: #b4b4b4;
        }
    }
    .btn-search {
        background: #ff5722;
        color: $white;
        line-height: 40px;
        height: 40px;
        text-align: center;
        min-width: 70px;
        border: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        font-size: 16px;
        -webkit-transition: all .3s ease-in-out 0s;
        -o-transition: all .3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }
}

.header-icons {
    position: relative;
    display: flex;
    align-items: center;
}

.header-account {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-left: 20px;
    .account-menu {
        color: $green;
    }
}

.header-carticon {
    display: inline-block;
    position: relative;
    text-align: center;
    background: $white;
    color: #454545;
    border-radius: 100%;
    font-size: 24px;
    font-weight: 700;
    height: 48px;
    width: 48px;
    line-height: 1;
    vertical-align: middle;
    border: 1px solid $white;
    i {
        line-height: 48px;
    }
    span.count {
        position: absolute;
        top: -3px;
        right: -6px;
        display: block;
        overflow: hidden;
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-weight: 600;
        font-size: 15px;
        color: $white;
        background: #ff5722;
        text-align: center;
        border-radius: 100%;
    }
    &:visited {
        color: #454545;
    }
}


.header-cart {
    display: inline-block;
    position: relative;
    
    .header-minicart {
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 9;
        display: none;
    }
    .minicart {
        border-bottom: 2px solid $green;
        width: 350px;
        background: $white;
        text-align: left;
        padding: 30px 20px 20px;
        -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        .minicart-header {
            max-height: 230px;
            overflow-y: auto;
        }
        .minicart-product {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 20px;
        }
        .minicart-productcontent {
            h6 {
                font-weight: 400;
                margin-bottom: 5px;
                a {
                    color: #454545;
                    &:hover {
                        color: $green;
                    }
                }
            }
            .minicart-productprice {
                color: $green;
                font-size: 16px;
                font-weight: 700;
            }
        }
        .minicart-productimage {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 70px;
            flex: 0 0 70px;
            max-width: 70px;
            margin-right: 20px;
            position: relative;
            a {
                display: inline-block;
            }
            img {
                width: 100%;
            }
            .minicart-productquantity {
                position: absolute;
                left: 0;
                top: 0;
                background: $green;
                height: 22px;
                width: 27px;
                line-height: 25px;
                text-align: center;
                border-radius: 100px;
                font-size: 12px;
                text-transform: uppercase;
                color: $white;
            }
        }
        .minicart-productcontent {
            padding-right: 10px;
        }
        .minicart-productclose {
            height: 30px;
            width: auto;
            padding: 0;
            line-height: 1;
            border: none;
            font-size: 18px;
            &:hover {
                color: $green;
            }
        }
        .minicart-pricing {
            padding-left: 0;
            padding-top: 20px;
            margin-bottom: 20px;
            list-style: none;
            border-top: 1px solid #e5e5e5;
            li {
                list-style: none;
                display: block;
                color: #7e7e7e;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 5px;
                &::after {
                    content: "";
                    clear: both;
                    display: table;
                }
                span {
                    color: $green;
                    float: right;
                }
            }
        }
    }
   
}





//mega-menucategory
.title-category-dropdown::after {
    content: "\f0c9";
    font-family: fontawesome;
    background: #ffffff;
    border: 1px solid #eee;
    display: inline-block;
    color: $green;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    margin-right: 20px;
    padding: 4px;
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
}

.wrap-category-dropdown {
    background: #fff;
    left: -190px;
    margin: 0;
    margin-top: 10px;
    position: absolute;
    top: 100%;
    width: 220px;
    z-index: 99999;
    display: none;
    padding: 10px;
    ul {
        padding: 0;
    }
    li {
        list-style-type: none;
    }
}

.list-category-dropdown {
    margin: 0;
    position: relative;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: $green;
        text-transform: uppercase;
    }
    li {
        padding: 8px;
        a {
            font-size: 14px;
        }
        a:hover {
            color: $green;
        }
        i {
            font-size: 16px;
            margin-right: 8px;
        }
    }
}

.cat-mega-menu {
    background: #fff none repeat scroll 0 0;
    left: 100%;
    position: absolute;
    top: -10px;
    border-top: 5px solid $orange;
    padding: 20px 30px;
    margin-left: 100px;
    opacity: 0;
    visibility: hidden;
    width: 900px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    li {
        list-style-type: none;
    }
}

.list-category-dropdown>li {
    &.has-cat-mega:hover>.cat-mega-menu {
        opacity: 1;
        visibility: visible;
        margin-left: 0;
    }
    &:hover>a::before {
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        width: 4px;
        z-index: 10;
    }
}

.list-cat-mega-menu a {
    color: #666;
    &:hover {
        color: $green;
    }
}

.list-cat-mega-menu {
    .title-cat-mega-menu {
        border-bottom: 1px solid #e5e5e5;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 10px;
        padding-bottom: 12px;
        text-transform: uppercase;
    }
    ul {
        padding-left: 0;
        li {
            color: #ccc;
            list-style: none;
            margin-bottom: 7px;
            padding: 1px;
        }
    }
}




/* iPhone 5 & 5S in portrait */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
    .header-carticon {
        right: 10px;
        position: absolute;
        top: -10px;
        right:  20px;
    }
    .header-searchbox {
        margin: 0 20px;
        margin-top: 10px
    }
}

/* iPad in portrait */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .header-carticon {
        right: 20px;
    }
}

/* Galaxy S5 portrait */

@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    .header-cart{
        right: 10px;
    }
    
    .header-searchbox {
        margin: 0 20px;
        margin-top: 10px
    }
}

@media screen and (device-width: 441px) and (device-height: 731px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    .header-cart{
        right: 20px;
    }
    
}

@media only screen and (max-width: 575px){
    .container {
        max-width: 100%;
    }
}



@media (max-width: 992px) {
    .header-middle {
        position: fixed;
        width: 100%;
        background: $white !important;
        z-index: 999;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
        .wrap-category-dropdown {
            background: #fff;
            left: 0;
            margin: 0;
            position: fixed;
            top: 120px;
            width: 100%;
            z-index: 99999;
            display: none;
            padding: 10px 0;
            ul.list-category-dropdown li {
                border-bottom: 1px solid #e5e5e5;
            }
        }
        #hamburger-desktop {
            display: none !important;
        }
        .hamburger-mobile-menu {
            display: inline;
        }
        .header {
            position: sticky;
        }
        .category-dropdown {
            position: relative;
        }
        .container {
            padding: 0;
        }
        .header-middle {
            padding: 10px 0;
        }
        .header-logo {
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 5px;
        }
        .header-logo img {
            max-height: 35px;
            margin-top: 10px;
        }
        .header-account {
            display: none;
        }
    }
    
    .header-cart {
        position: absolute;
        margin-right: 0;
        z-index: 999;
        top: -90px;
        right: 10px;
        .header-carticon {
            position: absolute;
            right: 0;
        }
        .minicart {
            position: fixed;
            left: 0px;
            top: 112px;
            width: 100%;
            /* height: 100vh; */
            margin-left: 0px;
        }
    }
   
       
        
    
}
