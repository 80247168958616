.form-message {
	margin-bottom: 0;
	text-align: center;
	&.error {
		margin-top: 20px;
		color: #f80707;
   }
	&.success {
		margin-top: 20px;
		color: #0d8d2d;
   }
}