// code for slider

.owl-carousel{
    .owl-dots{
        text-align:center;
        -webkit-tap-highlight-color:transparent
    }
    .owl-nav{
        margin-top:10px
    }
    .owl-nav [class*=owl-]{
        color:#FFF;
        font-size:14px;
        
        padding:4px 7px;
        background:#D6D6D6;
        display:inline-block;
        cursor:pointer;
        
    }
    
    .owl-nav .disabled{
        opacity:.5;
        cursor:default
    }
    .owl-nav.disabled+.owl-dots{
        margin-top:10px
    }
    .owl-dots .owl-dot{
        display:inline-block;
        zoom:1
    }
    .owl-dots .owl-dot span{
        width:10px;
        height:10px;
        margin:5px 7px;
        background:#D6D6D6;
        display:block;
        -webkit-backface-visibility:visible;
        transition:opacity .2s ease;
        border-radius:30px
    }
    .owl-dots .owl-dot.active span,.owl-dots .owl-dot:hover span{
        background:$orange
    }

    
}
.slider-item{ 
    max-width: 100%;
    img{
        max-width:100%;
    }
}

.highlight-slider {
    .slider-item{
        max-width: 992px;
    }
}
.products-slider, .categories-slider{
    .owl-nav {
        left: inherit;
        position: absolute;
        text-align: center;
        top: -58px;
        right: 0;
        padding: 0;
        font-size: 21px;
    }
    .owl-nav button.owl-prev,  .owl-nav button.owl-next {
        line-height: 40px;
        :hover{
            color:$green;
        }
    }
    
}
.banner-list-slider{
    .slider-item{
        width: 100%;
    }
}

@media (max-width: 992px) {
    .owl-carousel .owl-item img {
        display: block;
        max-width: 992px;
      
    }
}
@media (max-width: 640px) {
    .owl-carousel .owl-item img {
        display: block;
        max-width: 280px;
        max-height: 250px;
    }
}
