
@media only screen and (max-width: 575px) {
	blockquote {
			padding: 15px;
 }
}
@-webkit-keyframes crZoomInOut {
	0%,100% {
			-webkit-transform: scale(1);
			transform: scale(1);
 }
	50% {
			-webkit-transform: scale(1.3);
			transform: scale(1.3);
 }
}
@keyframes crZoomInOut {
	0%,100% {
			-webkit-transform: scale(1);
			transform: scale(1);
 }
	50% {
			-webkit-transform: scale(1.3);
			transform: scale(1.3);
 }
}
@-webkit-keyframes crZoomInOutInvisible {
	0% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
 }
	100% {
			-webkit-transform: scale(1.6);
			transform: scale(1.6);
			opacity: 0;
 }
}
@keyframes crZoomInOutInvisible {
	0% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
 }
	100% {
			-webkit-transform: scale(1.6);
			transform: scale(1.6);
			opacity: 0;
 }
}
@-webkit-keyframes crFadeInUp {
	from {
			opacity: 0;
			-webkit-transform: translate3d(0, 30px, 0);
			transform: translate3d(0, 30px, 0);
 }
	to {
			opacity: 1;
			-webkit-transform: none;
			transform: none;
 }
}
@keyframes crFadeInUp {
	from {
			opacity: 0;
			-webkit-transform: translate3d(0, 30px, 0);
			transform: translate3d(0, 30px, 0);
 }
	to {
			opacity: 1;
			-webkit-transform: none;
			transform: none;
 }
}
@-webkit-keyframes crSlideInDown {
	0% {
			-webkit-transform: translateY(-100%);
			transform: translateY(-100%);
 }
	100% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
 }
}
@keyframes crSlideInDown {
	0% {
			-webkit-transform: translateY(-100%);
			transform: translateY(-100%);
 }
	100% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
 }
}
@-webkit-keyframes crBounceIn {
	20%,40%,60%,80%,from,to {
			-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
 }
	0% {
			opacity: 0;
			-webkit-transform: scale3d(0.3, 0.3, 0.3);
			transform: scale3d(0.3, 0.3, 0.3);
 }
	20% {
			-webkit-transform: scale3d(1.1, 1.1, 1.1);
			transform: scale3d(1.1, 1.1, 1.1);
 }
	40% {
			-webkit-transform: scale3d(0.9, 0.9, 0.9);
			transform: scale3d(0.9, 0.9, 0.9);
 }
	60% {
			opacity: 1;
			-webkit-transform: scale3d(1.03, 1.03, 1.03);
			transform: scale3d(1.03, 1.03, 1.03);
 }
	80% {
			-webkit-transform: scale3d(0.97, 0.97, 0.97);
			transform: scale3d(0.97, 0.97, 0.97);
 }
	to {
			opacity: 1;
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
 }
}
@keyframes crBounceIn {
	20%,40%,60%,80%,from,to {
			-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
 }
	0% {
			opacity: 0;
			-webkit-transform: scale3d(0.3, 0.3, 0.3);
			transform: scale3d(0.3, 0.3, 0.3);
 }
	20% {
			-webkit-transform: scale3d(1.1, 1.1, 1.1);
			transform: scale3d(1.1, 1.1, 1.1);
 }
	40% {
			-webkit-transform: scale3d(0.9, 0.9, 0.9);
			transform: scale3d(0.9, 0.9, 0.9);
 }
	60% {
			opacity: 1;
			-webkit-transform: scale3d(1.03, 1.03, 1.03);
			transform: scale3d(1.03, 1.03, 1.03);
 }
	80% {
			-webkit-transform: scale3d(0.97, 0.97, 0.97);
			transform: scale3d(0.97, 0.97, 0.97);
 }
	to {
			opacity: 1;
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
 }
}
@-webkit-keyframes crSlideInRight {
	0% {
			-webkit-transform: translateX(150px);
			transform: translateX(150px);
			opacity: 0;
 }
	100% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
			opacity: 1;
 }
}
@keyframes crSlideInRight {
	0% {
			-webkit-transform: translateX(150px);
			transform: translateX(150px);
			opacity: 0;
 }
	100% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
			opacity: 1;
 }
}
@-webkit-keyframes carInOutAnimation {
	0% {
			-webkit-transform: translateX(200%);
			transform: translateX(200%);
 }
	18%,80% {
			-webkit-transform: translateX(-5%);
			transform: translateX(-5%);
 }
	20%,82% {
			-webkit-transform: translateX(5%);
			transform: translateX(5%);
 }
	22%,78% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
 }
	100% {
			-webkit-transform: translateX(-200%);
			transform: translateX(-200%);
 }
}
@keyframes carInOutAnimation {
	0% {
			-webkit-transform: translateX(200%);
			transform: translateX(200%);
 }
	18%,80% {
			-webkit-transform: translateX(-5%);
			transform: translateX(-5%);
 }
	20%,82% {
			-webkit-transform: translateX(5%);
			transform: translateX(5%);
 }
	22%,78% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
 }
	100% {
			-webkit-transform: translateX(-200%);
			transform: translateX(-200%);
 }
}
@-webkit-keyframes hoFill {
	0% {
			width: 0;
 }
	100% {
			width: 100%;
 }
}
@keyframes hoFill {
	0% {
			width: 0;
 }
	100% {
			width: 100%;
 }
}
