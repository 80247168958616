

.modal-content{
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    padding: 20px 30px 20px;

    &.login-modal{
        max-width: 450px;
        .modal-body{
            padding: 0;
        }
        .logo{
            width: 70px;
            height: 70px;
        }
        .title{
           
            font-size: 24px;
            color: #333;
            font-weight: 300;
        }
    }
   
    .close{
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
    }
}

@media (max-width: 600px) {
	.modal-content.login-modal{
        max-width: 410px;
    }
   
}
