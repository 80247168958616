@import 'https://fonts.googleapis.com/css?family=Lato';
@import 'variables';
@import 'components/basic';
@import 'components/spacing';
@import 'components/animation';
@import 'components/button';
@import 'components/form';
@import 'components/modal';
@import 'components/header';
@import 'components/header-mobile';
@import 'components/slider';
.hr-text {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
	opacity: .5;
	&:before {
	  content: '';
	  // use the linear-gradient for the fading effect
	  // use a solid background color for a solid bar
	  background: linear-gradient(to right, transparent, #818078, transparent);
	  position: absolute;
	  left: 0;
	  top: 50%;
	  width: 100%;
	  height: 1px;
	}
	&:after {
	  content: attr(data-content);
	  position: relative;
	  display: inline-block;
	  color: black;
  
	  padding: 0 .5em;
	  line-height: 1.5em;
	  // this is really the only tricky part, you need to specify the background color of the container element...
	  color: #818078;
	  background-color: #fcfcfa;
	}
  }
.oficial-store-area{
	
	li{
		display: inline-block;
	}
	img{ 
		max-width: 130px;
		max-height: 130px;
		margin: 20px;
	}
}
.highlight-area{
	
	.nav-link{
		border-radius: 0;
		color: #636363;
		outline: 1px solid #eee;
		font-size: 15px;
		font-weight: 600;
		&.active{
			background: $green;
		}
	}
}
.banner-ads-area{

}

.overlay {
	position: fixed;
    display:none; 
	height: 100%;
   /* color with alpha transparency */
    background-color: rgba(0, 0, 0, 0.5);

   /* stretch to screen edges */
	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	right: 0;
	z-index: 99;
}

.section{
	padding: 20px;
	background: $white;
	margin-top: 20px;
	.section-title h3::after {
		content: "";
		width: 100%;
		left: 0;
		bottom: -1px;
	}
	&.category-area{
		max-height: 230px;
	}
}
.klevv{
	background: url('../images/klevv.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.dropdown-list {
	position: absolute;
	top: 100%;
	left: auto;
	right: 0;
	background: $white;
	border-bottom: 2px solid $green;
	padding: 0 20px;
	min-width: 150px;
	text-align: left;
	-webkit-box-shadow: 0 2px 13.95px 1.05px rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 13.95px 1.05px rgba(0, 0, 0, 0.05);
	z-index: 99;
	display: none;
}

.dropdown-list li {
	list-style: none;
	display: block;
	&:not(:last-child) {
		border-bottom: 1px solid #e5e5e5;
   }
	a {
		display: block;
		width: 100%;
		clear: both;
		font-weight: 400;
		white-space: nowrap;
		background: 0 0;
		border: 0;
		padding: 10px 0;
		color: #7e7e7e;
		line-height: 25px;
		font-size: 13px;
		&:hover {
			color: $green;
	   }
   }
}
[class*=" flaticon-"] {
	&:after, &:before {
		font-size: inherit;
		margin-left: 0;
   }
}
[class^=flaticon-] {
	&:after, &:before {
		font-size: inherit;
		margin-left: 0;
   }
}
@media (min-width: 992px) {
	.col-2-of-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 25%;
   }
}
.tab-content {
	width: 100%;
	.tab-pane {
		display: block;
		height: 0;
		max-width: 100%;
		visibility: hidden;
		overflow: hidden;
		opacity: 0;
   }
}

.tab-content .tab-pane.active {
	height: auto;
	visibility: visible;
	opacity: 1;
	overflow: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.ho-button {
		font-size: 14px;
		padding: 5px 15px 8px;
		border-radius: 5px;
		&:link {
			font-size: 14px;
			padding: 5px 15px 8px;
			border-radius: 5px;
	   }
		i, &:link i {
			font-size: 17px;
	   }
   }
}
@media only screen and (max-width: 767px) {
	.ho-button {
		font-size: 14px;
		padding: 6px 15px;
		border-radius: 5px;
		&:link {
			font-size: 14px;
			padding: 6px 15px;
			border-radius: 5px;
	   }
		i, &:link i {
			font-size: 17px;
	   }
   }
}



.ho-readmore {
	color: #454545;
	&:hover {
		color: $green;
   }
}

.section-title {
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 8px;
	display: block;
	&::after {
		content: "";
		clear: both;
		display: table;
   }
	h3 {
		font-weight: 700;
		display: inline-block;
		margin-bottom: 0;
		line-height: 40px;
		position: relative;
		&::after {
			position: absolute;
			background: $green;
			height: 2px;
	   }
   }
	.nav li a {
		position: relative;
		background: 0 0;
		text-transform: uppercase;
		font-weight: 700;
		z-index: 2;
		cursor: pointer;
		vertical-align: middle;
   }
}

.section-title .nav {
	float: right;
	margin-right: 80px;
	li {
		display: inline-block;
		margin: 0 10px;
		&:first-child {
			margin-left: 0;
	   }
		&:last-child {
			margin-right: 0;
	   }
		a {
			height: 40px;
			display: inline-block;
			line-height: 40px;
			color: #ababab;
			font-size: 14px;
			padding: 0;
			-webkit-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all 0.3s ease;
			&.active, &:hover {
				color: #454545;
		   }
	   }
   }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.section-title .nav {
		margin-right: 60px;
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.section-title .nav {
		float: none;
		margin-right: 50px;
   }
}
@media only screen and (max-width: 767px) {
	.section-title .nav {
		float: none;
		margin-right: 50px;
   }
}
@media only screen and (max-width: 575px) {
	
	.section-title .nav {
		padding-top: 15px;
		padding-bottom: 10px;
		li {
			margin-left: 0;
			margin-right: 15px;
			a {
				height: auto;
				line-height: 24px;
		   }
	   }
   }
}


.small-title {
	padding-bottom: 10px;
}
.socialicons ul {
	padding-left: 0;
	margin-bottom: 0;
	display: inline-block;
	list-style: none;
	font-size: 0;
	li {
		display: inline-block;
		list-style: none;
		font-size: 18px;
		margin: 0 10px;
		&:first-child {
			margin-left: 0;
	   }
		&:last-child {
			margin-right: 0;
	   }
		a {
			display: inline-block;
			color: #454545;
			&:hover {
				color: $green;
		   }
	   }
   }
}
.socialicons-radial ul li {
	margin: 0 7px;
	a {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		color: #454545;
		background: $white;
		font-size: 18px;
		padding: 0;
		width: 50px;
		height: 50px;
		text-align: center;
		border: 1px solid #e5e5e5;
		border-radius: 100%;
		line-height: 1;
		&:hover {
			background: $green;
			border-color: $green;
			color: $white;
	   }
   }
}
@media only screen and (max-width: 767px) {
	.socialicons-radial ul li {
		margin: 0 4px;
		a {
			height: 38px;
			width: 38px;
			font-size: 15px;
			i {
				line-height: 38px;
		   }
	   }
   }
}
.breadcrumb{
	background: none;
	padding:  0;
}
.breadcrumb ul {
	display: inline-block;
	padding: 5px 0;
	margin-bottom: 0;
	font-size: 0;
	li {
		color: #454545;
		display: inline-block;
		padding: 8px 0;
		font-size: 14px;
		&::after {
			content: "\f142";
			font-family: "Material Design Icons";
			color: #7e7e7e;
			margin: 0 5px;
	   }
		&:last-child::after {
			display: none;
	   }
   }
}
.breadcrumb ul li a {
	color: #7e7e7e;
}

.pagination {
	display: -webkit-box;
	display: -ms-flexbox;
}

.pagination {
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-bottom: 1px solid #e5e5e5;
	p {
		padding-bottom: 10px;
		margin-bottom: 0;
		color: #454545;
   }
	ul {
		
		li {
			list-style: none;
			display: inline-block;
			a {
				font-weight: 400;
				color: $white;
				padding: 5px 10px;
				
				background: #ccc;
				border-radius: 2px;
				font-size: 14px;
				display: inline-block;
				i {
					font-size: 12px;
			   }
		   }
			&.active a, &:hover a {
				background: $green;
		   }
	   }
   }
}
.product {
	padding: 15px;
	-webkit-transition: all .3s ease-in-out 0s;
	-o-transition: all .3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	.product-link{
		margin-top: 5px;
	
		img{
			max-width: 30px;
			max-height: 30px;
			margin-right: 5px;
		}
	}
	
}
.product-actionbox, .product-flags {
	padding-left: 0;
	left: 0;
	list-style: none;
}
.product-thumb {
	position: relative;
	display: block;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	overflow: hidden;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: rgba(255, 255, 255, 0.75);
		opacity: 0;
		visibility: hidden;
		z-index: 2;
		transition: all 0.4s ease-out 0s;
   }
	
}
.product:hover .product-thumb {
	&::before {
		opacity: 1;
		visibility: visible;
		z-index: 2;
   }
	.product-backimage {
		visibility: visible;
		opacity: 1;
		z-index: 1;
		-webkit-transform: scale(1.05);
		-ms-transform: scale(1.05);
		transform: scale(1.05);
   }
}
.product-image {
	position: relative;
	z-index: 1;
}
.product-actionbox {
	display: block;
	position: absolute;
	width: 100%;
	z-index: 5;
	margin-bottom: 0;
	text-align: center;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 0;
	li {
		list-style: none;
		display: inline-block;
		margin: 0 5px;
		font-size: 18px;
		&:first-child {
			margin-left: 0;
	   }
		&:last-child {
			margin-right: 0;
	   }
		a {
			display: inline-block;
			background: #ededed;
			color: #454545;
			padding: 0;
			border: none;
			height: 45px;
			width: 45px;
			font-size: 18px;
			cursor: pointer;
			text-transform: capitalize;
			border-radius: 1000px;
			-webkit-transition: all .3s ease-in-out 0s;
			-o-transition: all .3s ease-in-out 0s;
			transition: all .3s ease-in-out 0s;
			-webkit-transform: scale(0.5);
			-ms-transform: scale(0.5);
			transform: scale(0.5);
			opacity: 0;
			text-align: center;
			&.is-active, &:hover {
				background: $green;
				color: $white;
		   }
			i {
				line-height: 45px;
		   }
	   }
   }
}

.product-countdown {
	text-align: center;
}
.product-content {
	padding-top: 15px;
}
.product-title {
	font-weight: 400;
	font-size: 15px;
	line-height: 24px;
	height: 65px;
	max-height: 65px;
	margin-bottom: 5px;
	a {
		color: #454545;
		&:hover {
			color: $green;
	   }
   }
}
.product-flags {
	position: absolute;
	top: 0;
	width: 100%;
	margin-bottom: 0;
	z-index: 1;
	li {
		position: absolute;
		left: 0;
		display: inline-block;
		background: #3fcc8d;
		color: $white;
		border-radius: 5px;
		padding: 0 5px;
		min-width: 55px;
		text-align: center;
		line-height: 25px;
		height: 25px;
		font-size: 13px;
		font-weight: 400;
		text-transform: uppercase;
   }
	.flag-discount {
		background: #d31129;
		left: auto;
		right: 0;
   }
}

@media only screen and (max-width: 767px) {
	.product-thumb::before {
		display: none;
   }
	.product-actionbox {
		position: relative;
		-webkit-transform: translate(0);
		-ms-transform: translate(0);
		transform: translate(0);
		margin-top: 15px;
		li a {
			opacity: 1;
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
	   }
   }
}
@media only screen and (max-width: 575px) {
	.product-thumb::before {
		display: none;
   }
	.product-actionbox {
		position: relative;
		-webkit-transform: translate(0);
		-ms-transform: translate(0);
		transform: translate(0);
		margin-top: 15px;
		li a {
			opacity: 1;
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
	   }
   }
}

 .product-horizontal .product-actionbox li a i {
	line-height: 40px;
	
}

.product-horizontal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 0;
	padding: 5px;
	border-bottom:1px solid #e5e5e5;
	width: 100%;
	
	.product-title{
		max-height: 30px;
		height: 30px;
	}
	.product-image {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		max-width: 100px;
		margin-right: 15px;
		.btn{
			padding: 6px 15px;
	}

	
   }
	.product-content {
		padding: 0;
		
   }
	.product-actionbox {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
		top: auto;
		bottom: 0;
		right: auto;
		left: calc(100% + 15px);
		text-align: left;
		li a {
			text-align: center;
			height: 40px;
			width: 40px;
	   }
   }
	&:hover {
		-webkit-box-shadow: none;
		box-shadow: none;
   }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.product-horizontal .product-image {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 65px;
		flex: 0 0 65px;
		max-width: 65px;
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-horizontal .product-image {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 65px;
		flex: 0 0 65px;
		max-width: 65px;
   }
}
.rattingbox {
	display: block;
	span {
		color: #7e7e7e;
		font-size: 14px;
		display: inline-block;
		&.active {
			color: #fc3;
	   }
   }
}
.pricebox {
	.oldprice {
		color: #ababab;
		margin-right: 5px;
		display: inline-block;
   }
	.price {
		display: inline-block;
		font-weight: 700;
		font-size: 17px;
		color: $green;
   }
}
.herobanner-single {
	position: relative;
	vertical-align: middle;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	width: 824px;
	img {
		width: 100%;
   }
}
.herobanner-content {
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%) translateZ(0);
	transform: translateY(-50%) translateZ(0);
	padding: 15px 45px;
	max-width: 500px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	h4 {
		letter-spacing: 6px;
		color: #ababab;
		font-weight: 700;
   }
	h1 {
		font-size: 52px;
		font-weight: 500;
		line-height: 1.1;
		span {
			color: $green;
			display: inline-block;
	   }
   }
}
.imgbanner a, .nice-select {
	display: block;
}
.herobanner-content {
	p {
		font-size: 18px;
		line-height: 27px;
		color: #7e7e7e;
   }
	
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.herobanner-content {
		padding: 30px;
		max-width: 380px;
		h4 {
			margin-bottom: 8px;
			letter-spacing: 0;
	   }
		h1 {
			font-size: 32px;
			margin-bottom: 8px;
	   }
		p {
			font-size: 14px;
			line-height: 24px;
	   }
	
   }
	.herobanner.slider-dots .slick-dots {
		bottom: 15px;
   }
}
@media only screen and (max-width: 767px) {
	.herobanner-content {
		padding: 30px;
		max-width: 380px;
		h4 {
			margin-bottom: 8px;
			letter-spacing: 0;
	   }
		h1 {
			font-size: 32px;
			margin-bottom: 8px;
	   }
		p {
			font-size: 14px;
			line-height: 24px;
	   }
	
   }
	.herobanner.slider-dots .slick-dots {
		bottom: 15px;
   }
}
.imgbanner {
	position: relative;
	overflow: hidden;
	z-index: 1;
	&::after, &::before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		background: rgba(255, 255, 255, 0.4);
		left: 0;
		top: 0;
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
		opacity: 1;
		-webkit-transition: all .5s ease-in-out 0s;
		-o-transition: all .5s ease-in-out 0s;
		transition: all .5s ease-in-out 0s;
		z-index: 2;
		pointer-events: none;
   }
	img {
		-webkit-transition: all .5s ease-in-out 0s;
		-o-transition: all 0.5s ease-in-out 0s;
   }
}
.quickmodal {
	-webkit-transition: all .5s ease-in-out 0s;
	-o-transition: all 0.5s ease-in-out 0s;
}
.imgbanner {
	&::before {
		-webkit-transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		transform-origin: 0 0;
   }
	&:after {
		-webkit-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
   }
	img {
		width: 100%;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		transition: all 0.5s ease-in-out 0s;
   }
	&:hover {
		&::after, &::before {
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
			opacity: 0;
			z-index: -1;
	   }
		img {
			-webkit-transform: scale(1.2);
			-ms-transform: scale(1.2);
			transform: scale(1.2);
	   }
   }
}
.imgbanner-2 {
	&::after, &::before {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
		opacity: 0;
		-webkit-transform-origin: center center;
		-ms-transform-origin: center center;
		transform-origin: center center;
   }
	&:hover {
		&::after {
			opacity: 1;
			z-index: 1;
	   }
		img {
			-webkit-transform: scale(1.08);
			-ms-transform: scale(1.08);
			transform: scale(1.08);
	   }
   }
}
.nice-select {
	width: 100%;
	border-radius: 0;
	float: none;
	background-color: transparent;
	border: 1px solid #e5e5e5;
	padding: 0 15px;
	&::after {
		content: "\f3d0";
		font-family: "Ionicons";
		position: absolute;
		left: auto;
		right: 15px;
		height: 35px;
		line-height: 35px;
		width: 8px;
		border-radius: 100%;
		display: inline-block;
		font-size: 14px;
		padding: 0;
		border: none;
		-webkit-transform: rotate(0);
		-ms-transform: rotate(0);
		transform: rotate(0);
		top: 7px;
		bottom: auto;
		-webkit-transform-origin: 50% 50%;
		-ms-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		text-align: center;
   }
	span.current {
		display: block;
		position: relative;
		color: #7e7e7e;
		letter-spacing: 0;
		font-size: 14px;
		padding: 0;
   }
	.list {
		width: 100%;
		border-radius: 0;
		margin-top: 0;
		max-height: 280px;
		overflow-y: auto;
		z-index: 75;
   }
	.option {
		line-height: 30px;
		min-height: 30px;
		padding-left: 15px;
		padding-right: 15px;
   }
	&.open {
		&::after {
			-webkit-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
	   }
		border-color: $green;
   }
	&:active, &:focus {
		border-color: $green;
   }
}
.featurebox {
	position: relative;
	padding-left: 70px;
	padding-top: 20px;
	padding-bottom: 15px;
	max-width: 320px;
	vertical-align: center;
	display: flex;
	i {
		position: absolute;
		left: 0;
		top: 17px;
		font-size: 40px;
		display: inline-block;
		height: auto;
		height: 70px;
		vertical-align: middle;
		color: #aaa;
   }
	h5{
		vertical-align: middle;
   }
}
 
.featurebox {
	i {
		&[class*=" flaticon-"] {
			&:after, &:before {
				vertical-align: text-bottom;
		   }
	   }
		&[class^=flaticon-] {
			&:after, &:before {
				vertical-align: text-bottom;
		   }
	   }
   }
	h5 {
		margin-bottom: 5px;
   }
	p {
		font-size: 13px;
		line-height: 23px;
		margin-bottom: 0;
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.featurebox {
		max-width: 98%;
   }
}


.newsletter-form, .slider-dots {
	position: relative;
}


.brand-area{
	padding:20px 0;
	ul{
		margin: 0;
	}
	li{
		width: 12.5%;
		outline: 1px solid #eee;
		padding: 0;
		list-style-type: none;
		display: inline-block;
		
		img{
			
		}
	}

}

@media only screen and (max-width: 767px) {
	.countdown-pack {
		margin: 0 3px;
		min-width: 48px;
		min-height: 48px;
   }
}
.category {
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	padding-top: 30px;
}
.category-thumb {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 120px;
	flex: 0 0 120px;
	max-width: 120px;
	width: 120px;
	height: 120px;
	border-radius: 1000px;
	overflow: hidden;
	margin-right: 15px;
	display: block;
	img {
		width: 100%;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		transition: all 0.3s ease-in-out 0s;
   }
}
.category-title {
	margin-bottom: 5px;
}
.category-productcounter {
	display: block;
	color: #ababab;
	font-style: italic;
	margin-bottom: 5px;
}
.category-productlink {
	color: $green;
	display: block;
	line-height: 20px;
	border-bottom: 1px solid transparent;
	i {
		vertical-align: middle;
		font-size: 16px;
		padding-left: 5px;
   }
	
}
.category:hover .category-thumb img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.category-thumb {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 90px;
		flex: 0 0 90px;
		max-width: 90px;
		width: 90px;
		height: 90px;
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.category-thumb {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 90px;
		flex: 0 0 90px;
		max-width: 90px;
		width: 90px;
		height: 90px;
   }
}
.category-wrapper {
	margin-top: -7px;
	width: 100%;
}




form .single-input label, .sn-progress .progress h6 {
	font-family: Roboto,sans-serif;
	font-weight: 400;
}

.video-item {
	padding: 15px;
	-webkit-transition: all .5s ease-in 0s;
	-o-transition: all .5s ease-in 0s;
	transition: all 0.5s ease-in 0s;
}

.blogitem {
	padding: 15px;
	-webkit-transition: all .5s ease-in 0s;
	-o-transition: all .5s ease-in 0s;
	transition: all 0.5s ease-in 0s;
}
.blogitem-thumb {
	position: relative;
	overflow: hidden;
	z-index: 1;
	&::after, &::before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		background: rgba(255, 255, 255, 0.4);
		left: 0;
		top: 0;
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
		opacity: 1;
		-webkit-transition: all .5s ease-in 0s;
		-o-transition: all .5s ease-in 0s;
		transition: all .5s ease-in 0s;
		z-index: 2;
		pointer-events: none;
   }
}
.blogitem2-image img, .team-member {
	-webkit-transition: all .3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
}
.blogitem-thumb {
	&::before {
		-webkit-transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		transform-origin: 0 0;
   }
	&:after {
		-webkit-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
   }
	a {
		display: block;
   }
	img {
		width: 100%;
   }
	&:hover {
		&::after, &::before {
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
			opacity: 0;
			z-index: -1;
	   }
   }
}
.blogitem-content {
	text-align: center;
	padding-top: 30px;
}
.blogitem-title {
	font-weight: 400;
	margin-bottom: 30px;
	a {
		color: #454545;
		&:hover {
			color: $green;
	   }
   }
}
.blogitem-meta {
	border-top: 1px solid rgba(229, 229, 229, 0.5);
	border-bottom: 1px solid rgba(229, 229, 229, 0.5);
	padding: 10px 0;
	span {
		font-size: 13px;
		color: #454545;
		display: inline-block;
		&:not(:last-child) {
			margin-right: 20px;
	   }
		i {
			margin-right: 5px;
			font-size: 16px;
	   }
		a {
			color: #454545;
			&:hover {
				color: $green;
		   }
	   }
   }
}
.blogitem:hover {
	-webkit-box-shadow: 0 2px 13.95px 1.05px rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 13.95px 1.05px rgba(0, 0, 0, 0.05);
}
.blogs-wrapper {
	margin-top: -30px;
}
.blogitem2 {
	margin-top: 30px;
}
.blogitem2-image {
	position: relative;
	overflow: hidden;
	a {
		display: block;
   }
	img {
		transition: all .3s ease-in-out 0s;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
   }
}
.blogitem2-content {
	padding-top: 30px;
	h2, h4 {
		margin-bottom: 7px;
   }
	h2 a, h4 a {
		color: #454545;
   }
	h2 a:hover, h4 a:hover {
		color: $green;
   }
	p {
		font-size: 15px;
   }
}
.blogitem2-meta {
	margin-bottom: 15px;
	span {
		color: #7e7e7e;
		&:after {
			content: "|";
			padding: 0 5px;
	   }
		&:last-child::after {
			display: none;
	   }
		a {
			color: #454545;
			&:hover {
				color: $green;
		   }
	   }
   }
}
.blogitem2:hover .blogitem2-image img {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}
.commentlist > h6 {
	font-size: 16px;
	margin-bottom: 25px;
}
.single-comment {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 25px;
	.single-comment-content p, &:last-child {
		margin-bottom: 0;
   }
	.single-comment-thumb {
		height: 60px;
		width: 60px;
		overflow: hidden;
		border-radius: 100px;
		margin-right: 25px;
		border: 1px solid #d5d5d5;
   }
	.single-comment-content {
		-ms-flex-negative: 100;
		flex-shrink: 100;
		border: 1px solid #d5d5d5;
		padding: 25px;
		position: relative;
		border-radius: 3px;
		&::before {
			content: "";
			position: absolute;
			left: -6px;
			right: auto;
			top: 10px;
			height: 10px;
			width: 10px;
			background: $white;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
			border-left: 1px solid #d5d5d5;
			border-bottom: 1px solid #d5d5d5;
	   }
   }
	.single-comment-content-top {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		h6 {
			padding-right: 30px;
			a {
				color: #454545;
				&:hover {
					color: $green;
			   }
		   }
	   }
		.ratting-box {
			margin-bottom: 15px;
			-ms-flex-item-align: start;
			align-self: flex-start;
	   }
		.reply-button {
			background: #575757;
			color: $white;
			padding: 2px 10px;
			-ms-flex-item-align: start;
			align-self: flex-start;
			border-radius: 3px;
			font-size: 13px;
			margin-bottom: 15px;
			&:hover {
				background: $green;
		   }
	   }
   }
	&.single-comment-reply {
		margin-left: 30px;
   }
}
@media only screen and (max-width: 575px) {
	.single-comment {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		.single-comment-content {
			margin-top: 20px;
			padding: 15px;
			&::before {
				left: 20px;
				top: -6px;
				border: none;
				border-left: 1px solid #d5d5d5;
				border-top: 1px solid #d5d5d5;
		   }
	   }
   }
}
.commentbox > h6 {
	font-size: 16px;
	margin-bottom: 25px;
}

.sn-progress-wrap {
	margin-top: -30px;
}
.sn-progress {
	position: relative;
	margin-left: 30px;
	padding: 15px 0;
	margin-top: 30px;
	.progress-bar-count {
		position: absolute;
		display: block;
		background: #ff5722;
		border-radius: 100%;
		height: 60px;
		width: 60px;
		left: 0;
		top: 50%;
		line-height: 60px;
		margin-top: -30px;
		margin-left: -30px;
		text-align: center;
		font-size: 14px;
		color: #454545;
		z-index: 1;
   }
	.progress {
		background: #f2f2f2;
		height: 30px;
		line-height: 30px;
		border-radius: 0;
		h6 {
			color: $white;
			font-size: 14px;
			margin-bottom: 0;
			padding-left: 45px;
	   }
   }
	.progress-bar {
		background: $green;
		text-align: left;
		-webkit-transform: translateX(-100%);
		-ms-transform: translateX(-100%);
		transform: translateX(-100%);
		-webkit-transition: all 1s ease-out 0s;
		-o-transition: all 1s ease-out 0s;
		transition: all 1s ease-out 0s;
		&.fill {
			-webkit-transform: translateX(0);
			-ms-transform: translateX(0);
			transform: translateX(0);
	   }
   }
}
.team-member {
	background: #f5f5f5;
	padding-top: 6px;
	margin-top: 30px;
	transition: all 0.3s ease-in-out 0s;
	.team-member-content {
		h5, p {
			-webkit-transition: all .3s ease-in-out 0s;
			-o-transition: all 0.3s ease-in-out 0s;
	   }
   }
	img {
		width: 100%;
   }
	.team-member-content {
		text-align: center;
		padding: 15px;
		h5 {
			font-weight: 400;
			color: #454545;
			font-size: 16px;
			margin-bottom: 5px;
			transition: all 0.3s ease-in-out 0s;
	   }
		p {
			margin-bottom: 0;
			letter-spacing: 5px;
			text-transform: uppercase;
			transition: all 0.3s ease-in-out 0s;
	   }
   }
	&:hover {
		background: $green;
		h5, p {
			color: $white;
	   }
   }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.team-member {
		margin-left: 0;
		margin-right: 0;
   }
}
@media only screen and (max-width: 767px) {
	.catmenu-triggeru{
		margin-top: 20px;
   }
	.ho-form .single-input.single-input-half {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
   }
	.team-member {
		margin-left: 0;
		margin-right: 0;
   }
}
.shop-widgets {
	.widget-title {
		font-size: 18px;
		font-weight: 700;
		color: #454545;
		position: relative;
		display: block;
		text-transform: uppercase;
   }
	.single-widget ul {
		padding-left: 0;
		margin-bottom: 0;
		li {
			list-style: none;
			display: block;
	   }
   }
	.shop-widgetbox {
		border: 1px solid #e5e5e5;
		.single-widget {
			padding: 20px;
			border-top: 1px solid #e5e5e5;
			&:first-child {
				border-top: 0;
		   }
	   }
   }
	.widget-categories {
		border: 1px solid #e5e5e5;
		padding: 20px;
		ul li {
			padding: 8px 0;
			border-top: 1px solid #e5e5e5;
			&:first-child {
				border-top: none;
		   }
			&:last-child {
				padding-bottom: 0;
		   }
			a {
				text-transform: uppercase;
				color: #7e7e7e;
				font-weight: 600;
				display: block;
				&::after {
					content: "";
					clear: both;
					display: table;
			   }
				&:hover {
					color: $green;
			   }
				span {
					float: right;
					background: $orange;
					padding: 0 10px;
					border-radius: 2px;
					color: $white;
					font-size: 12px;
			   }
		   }
	   }
   }
	.widget-color ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		li {
			min-width: 50%;
	   }
   }
	.widget-filters .single-widget-range-price {
		margin-top: 20px;
		font-weight: 600;
		color: #454545;
		b {
			color: #7e7e7e;
	   }
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.team-member {
		margin-left: 0;
		margin-right: 0;
   }
	.shop-widgets {
		margin-top: 50px;
   }
}
@media only screen and (max-width: 767px) {
	.shop-widgets {
		margin-top: 50px;
   }
}
.blog-widgets {
	margin-top: -30px;
	.widget-title {
		border-bottom: 1px solid #e5e5e5;
		padding-bottom: 5px;
		position: relative;
		margin-bottom: 20px;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: -1px;
			height: 2px;
			width: 80px;
			background: $green;
	   }
   }
	.single-widget {
		margin-top: 30px;
   }
	.widget-search-form {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		input {
			&[type=search], &[type=text] {
				width: calc(100% - 50px);
		   }
			&[type=search]::-webkit-input-placeholder, &[type=text]::-webkit-input-placeholder, &[type=search]:-moz-placeholder, &[type=text]:-moz-placeholder, &[type=search]::-moz-placeholder, &[type=text]::-moz-placeholder, &[type=search]:-ms-input-placeholder, &[type=text]:-ms-input-placeholder {
				color: #7e7e7e;
		   }
	   }
		[type=submit] {
			display: inline-block;
			border: 1px solid $green;
			background: $green;
			color: $white;
			font-size: 16px;
			text-align: center;
			&:hover {
				background: $orange;
				border: 1px solid $orange;
		   }
	   }
   }
	.widget-categories ul {
		margin-bottom: 0;
		padding-left: 0;
		li {
			display: block;
			list-style: none;
			&:not(:last-child) {
				margin-bottom: 8px;
		   }
			a {
				display: block;
				color: #454545;
				&:hover {
					color: $green;
			   }
		   }
	   }
   }
	.widget-tags ul {
		padding-left: 0;
		margin-bottom: 0;
		font-size: 0;
		margin-left: -5px;
		margin-top: -5px;
		li {
			list-style: none;
			display: inline-block;
			font-size: 14px;
			margin-left: 5px;
			margin-top: 5px;
			a {
				display: inline-block;
				background: $green;
				padding: 6px 15px 5px;
				color: $white;
				text-transform: uppercase;
		   }
	   }
   }
	.widget-recentblogs ul {
		padding-left: 0;
		margin-bottom: 0;
		li {
			list-style: none;
			position: relative;
			padding-left: 100px;
			min-height: 52px;
			&:not(:last-child) {
				margin-bottom: 15px;
		   }
			.image {
				width: 80px;
				position: absolute;
				left: 0;
				top: 0;
		   }
			h6 {
				font-size: 15px;
				margin-bottom: 3px;
				a {
					color: #454545;
					&:hover {
						color: $green;
				   }
			   }
		   }
			span a {
				color: #454545;
				&:hover {
					color: $green;
			   }
		   }
	   }
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.blog-widgets {
		margin-top: 0;
   }
}
@media only screen and (max-width: 767px) {
	.blog-widgets {
		margin-top: 0;
   }
}


.pdetails-largeimages {
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}
.pdetails-thumbs {
	max-width: 450px;
	margin: 10px auto 0;
	.slick-slide {
		margin: 10px;
   }
}
.pdetails-singlethumb {
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}
.pdetails .rattingbox {
	margin-bottom: 10px;
}
.pdetails-pricebox, .pdetails-quantity {
	margin-bottom: 20px;
}
.pdetails-pricebox {
	.oldprice {
		font-weight: 400;
		font-size: 20px;
		line-height: 30px;
		color: #ababab;
		margin-right: 10px;
   }
	.price {
		font-weight: 700;
		font-size: 24px;
		line-height: 30px;
		color: $green;
   }
	.badge {
		font-weight: 700;
		font-size: 12px;
		line-height: 30px;
		color: $white;
		padding: 0 10px;
		background: #d31129;
		vertical-align: 5px;
		border-radius: 5px;
		margin-left: 10px;
   }
}
.pdetails-color ul li + li, .pdetails-size ul li + li, .pdetails-socialshare ul li + li {
	margin-left: 5px;
}
.pdetails p {
	margin-top: 20px;
}
.pdetails-allinfo, .pdetails-description, .pdetails-moreinfo, .pdetails-quantity, .pdetails-reviews {
	margin-top: 30px;
}
.pdetails-quantity .ho-button {
	padding: 6.5px 15px;
	&:link {
		padding: 6.5px 15px;
   }
}
.details-categories, .pdetails-color, .pdetails-size, .pdetails-socialshare, .pdetails-tags {
	padding: 12px 0;
}
.details-categories > span, .pdetails-color > span, .pdetails-size > span, .pdetails-socialshare > span, .pdetails-tags > span {
	display: inline-block;
	margin-right: 20px;
	color: #454545;
	font-weight: 500;
}
.details-categories ul, .pdetails-color ul, .pdetails-size ul, .pdetails-socialshare ul, .pdetails-tags ul {
	display: inline-block;
	padding-left: 0;
	margin-bottom: 0;
}
.details-categories ul li, .pdetails-color ul li, .pdetails-size ul li, .pdetails-socialshare ul li, .pdetails-tags ul li {
	list-style: none;
	display: inline-block;
}
.details-categories ul li:last-child::after, .pdetails-color ul li:last-child::after, .pdetails-size ul li:last-child::after {
	display: none;
}
.pdetails-socialshare ul li {
	&::after, &:last-child::after {
		display: none;
   }
}
.pdetails-tags ul li:last-child::after {
	display: none;
}
.details-categories ul li::after, .pdetails-color ul li::after, .pdetails-size ul li::after, .pdetails-socialshare ul li::after, .pdetails-tags ul li::after {
	content: ",";
}
.details-categories ul li a, .pdetails-color ul li a, .pdetails-size ul li a, .pdetails-socialshare ul li a, .pdetails-tags ul li a {
	color: #7e7e7e;
	font-style: italic;
}
.details-categories ul li a:hover, .pdetails-color ul li a:hover, .pdetails-size ul li a:hover, .pdetails-socialshare ul li a:hover, .pdetails-tags ul li a:hover {
	color: $green;
}
.pdetails-socialshare ul li a {
	display: inline-block;
	height: 35px;
	width: 35px;
	line-height: 1;
	text-align: center;
	color: #7e7e7e;
	border: 1px solid #c5c5c5;
	border-radius: 100%;
	font-size: 16px;
	i {
		line-height: 33px;
   }
	&:hover {
		color: $white;
		border-color: $green;
		background: $green;
   }
}
.pdetails-color ul li::after, .pdetails-size ul li::after {
	display: none;
}
.pdetails-color ul li span, .pdetails-size ul li span {
	display: inline-block;
	height: 18px;
	width: 18px;
	background: $green;
	vertical-align: middle;
	border: 1px solid #e5e5e5;
	position: relative;
	cursor: pointer;
}
.pdetails-color ul li span::before, .pdetails-size ul li span::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	height: calc(100% + 2px);
	width: calc(100% + 2px);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border: 1px solid #000;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all .3s ease-in-out 0s;
	-o-transition: all .3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}
.pdetails-moreinfo a, .pdetails-slidersingleimage {
	border: 1px solid #e5e5e5;
}
.pdetails-color ul li.checked span::before, .pdetails-size ul li.checked span::before {
	visibility: visible;
	opacity: 1;
}
.pdetails-color ul li.red span, .pdetails-size ul li.red span {
	background: #d858b1;
}
.pdetails-color ul li.green span, .pdetails-size ul li.green span {
	background: #5834fb;
}
.pdetails-color ul li.blue span, .pdetails-size ul li.blue span {
	background: #b1b1b1;
}
.pdetails-color ul li.black span {
	background: #000;
}
.pdetails-size ul li {
	&.black span {
		background: #000;
   }
	span {
		width: 26px;
		height: 26px;
		text-align: center;
		line-height: 24px;
		background: 0 0;
		font-size: 10px;
   }
}
.pdetails-allinfotab {
	background: #f4f4f4;
	padding: 15px 0;
	li {
		display: inline-block;
		a {
			display: inline-block;
			vertical-align: middle;
			text-transform: uppercase;
			color: #7e7e7e;
			font-weight: 700;
			font-size: 16px;
			padding: 3px 25px;
			&.active {
				color: #454545;
		   }
	   }
   }
}
.pdetails-moreinfo b {
	color: #454545;
}
.pdetails-description {
	line-height: 30px;
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		li {
			list-style: none;
	   }
   }
}
.pdetails-moreinfo {
	a {
		display: inline-block;
		margin-bottom: 15px;
   }
	p {
		margin-bottom: 0;
   }
}
.pdetails-affiliatebox {
	margin-top: 28px;
	margin-bottom: 5px;
	.ho-button {
		margin-right: 10px;
		padding: 9px 20px;
		vertical-align: top;
		margin-bottom: 15px;
		&:link {
			margin-right: 10px;
			padding: 9px 20px;
			vertical-align: top;
			margin-bottom: 15px;
	   }
   }
	.product-actionbox {
		-webkit-transform: translate(0);
		-ms-transform: translate(0);
		transform: translate(0);
		position: relative;
		display: inline-block;
		width: auto;
		margin-top: 0;
		margin-bottom: 15px;
   }
}
.pdetails-images-vertical .pdetails-images::after, .pdetails-imagesgallery::after {
	content: "";
	clear: both;
	display: table;
}
.pdetails-affiliatebox .product-actionbox li a {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}
.pdetails-imagesgallery {
	margin-left: -15px;
	margin-top: -15px;
	.pdetails-singleimage {
		margin-left: 15px;
		margin-top: 15px;
		max-width: calc(50% - 15px);
		float: left;
		border: 1px solid #e5e5e5;
   }
}
.pdetails-sliderimages {
	margin-left: -15px;
	margin-right: -15px;
	.slick-slide {
		padding: 0 15px;
   }
	.slider-navigation-arrow {
		visibility: hidden;
		opacity: 0;
   }
	&:hover .slider-navigation-arrow {
		visibility: visible;
		opacity: 1;
   }
}
.pdetails-groupproduct {
	margin-top: 25px;
	.table, .table-bordered {
		border-color: #e5e5e5;
		margin-bottom: 0;
   }
	.table {
		td, tr {
			text-align: center;
			vertical-align: middle;
	   }
   }
	.table-bordered {
		td, tr {
			text-align: center;
			vertical-align: middle;
	   }
   }
	.table .product-quantity, .table-bordered .product-quantity {
		min-width: 145px;
   }
	.table .quantity-select, .table-bordered .quantity-select {
		margin-right: 0;
		margin-left: 10px;
   }
	.table .pro-title, .table-bordered .pro-title {
		font-weight: 700;
		color: #454545;
		min-width: 200px;
   }
	.table .pro-price, .table-bordered .pro-price {
		font-weight: 700;
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.pdetails-content {
		margin-top: 50px;
   }
}
@media only screen and (max-width: 767px) {
	.pdetails-content {
		margin-top: 30px;
   }
	.pdetails-sliderimages .slider-navigation-arrow {
		visibility: visible;
		opacity: 1;
   }
}
.pdetails-images-vertical {
	.pdetails-largeimages {
		max-width: 428px;
		float: right;
   }
	.pdetails-thumbs {
		float: left;
		max-width: 110px;
		margin-top: -5px;
		margin-left: -5px;
		.slick-list {
			padding: 0 !important;
	   }
		.slick-slide {
			margin: 5px;
	   }
   }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.pdetails-images-vertical {
		.pdetails-largeimages {
			max-width: 359px;
	   }
		.pdetails-thumbs {
			max-width: 90px;
	   }
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.pdetails-images-vertical .pdetails-largeimages {
		max-width: 577px;
   }
}
@media only screen and (max-width: 767px) {
	.pdetails-images-vertical {
		.pdetails-largeimages {
			max-width: 408px;
	   }
		.pdetails-thumbs {
			max-width: 100px;
	   }
   }
}
@media only screen and (max-width: 575px) {
	.pdetails-images-vertical {
		.pdetails-largeimages {
			max-width: 100%;
	   }
		.pdetails-thumbs {
			max-width: 100%;
			margin-left: 0;
			margin-top: 8px;
	   }
   }
}
.quantity-select {
	display: inline-block;
	position: relative;
	max-width: 80px;
	vertical-align: middle;
	margin-right: 10px;
	overflow: hidden;
	input {
		text-align: center;
		padding: 0 33px 0 10px;
		height: 40px;
   }
	.qtybutton {
		position: absolute;
		height: 20.5px;
		width: 24px;
		line-height: 1;
		cursor: pointer;
		border: 1px solid #e5e5e5;
		right: 0;
		text-align: center;
		z-index: 1;
		font-size: 0;
		i {
			font-size: 12px;
			line-height: 20px;
	   }
		&.inc {
			top: 0;
			bottom: auto;
	   }
		&.dec {
			bottom: 0;
			top: auto;
	   }
   }
}
.quickmodal {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 40px 0;
	z-index: -2;
	opacity: 0;
	visibility: hidden;
	transition: all .5s ease-in-out 0s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	&.is-visible {
		opacity: 1;
		visibility: visible;
		z-index: 100;
   }
	.body-overlay {
		background: rgba(22, 21, 21, 0.85);
		position: absolute;
		z-index: 1;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
   }
}
.quickmodal-inside {
	position: relative;
	background: $white;
	padding: 30px 15px;
	max-height: 100%;
	overflow-y: auto;
	z-index: 2;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: all .5s ease-in-out 0s;
	-o-transition: all .5s ease-in-out 0s;
	transition: all 0.5s ease-in-out 0s;
}
.quickmodal.is-visible .quickmodal-inside {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	visibility: visible;
}
.quickmodal-close {
	position: absolute;
	left: auto;
	right: 0;
	top: -10px;
	z-index: 1;
	border: none;
	color: $white;
	font-size: 50px;
	padding: 10px;
}
.shop-filters {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 5px 15px;
	border: 1px solid #e5e5e5;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.shop-filters-viewmode button {
	border: none;
	padding: 0;
	font-size: 24px;
	margin-right: 15px;
	&.is-active {
		color: $green;
   }
}
.shop-filters-sortby {
	position: relative;
	.select-sortby {
		display: inline-block;
   }
	.select-sortby-current {
		height: 35px;
		line-height: 35px;
		border: none;
		position: relative;
		padding-right: 20px;
		padding-left: 10px;
		&::after {
			content: "\f140";
			font-family: "Material Design Icons";
			position: absolute;
			right: 0;
			font-size: 15px;
			top: 0;
			height: 100%;
	   }
   }
}
.cart-pricing-table, .cart-table .table thead th {
	font-family: Roboto, sans-serif;
}
.compare-table .table {
	tbody th, thead th {
		font-family: Roboto, sans-serif;
   }
}
.different-address-form-trigger, .myaccount-tab-trigger li a {
	font-family: Roboto, sans-serif;
}
.order-infobox .table {
	tfoot th, thead th {
		font-family: Roboto, sans-serif;
   }
}
.shop-filters-sortby .select-sortby-list {
	min-width: 200px;
}
.shop-filters-viewitemcount {
	font-weight: 700;
}
.shop-page-products {
	.product .product-content-description {
		display: none;
		padding-top: 10px;
   }
	&.list-view-active {
		[class*=col-] {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
	   }
		.product {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			.product-image {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 33%;
				flex: 0 0 33%;
				max-width: 33%;
		   }
			.product-content {
				padding: 15px 0 55px 15px;
				width: 100%;
				.product-content-description {
					display: block;
			   }
		   }
			.product-actionbox {
				-webkit-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
				top: auto;
				bottom: 10px;
				right: auto;
				left: calc(100% + 15px);
				text-align: left;
				width: auto;
				white-space: nowrap;
				li a {
					text-align: center;
					height: 40px;
					width: 40px;
					i {
						line-height: 40px;
				   }
			   }
		   }
	   }
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.shop-page-products.list-view-active .product .product-actionbox li a {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		opacity: 1;
		visibility: visible;
   }
}
@media only screen and (max-width: 767px) {
	.shop-page-products.list-view-active .product .product-actionbox {
		margin-top: 95px;
		li a {
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
			opacity: 1;
			visibility: visible;
	   }
   }
}
@media only screen and (max-width: 575px) {
	.shop-page-products.list-view-active .product {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		.product-image {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
	   }
		.product-actionbox {
			width: 100%;
			left: 0;
			margin-top: 15px;
	   }
		.product-content {
			text-align: left;
			padding: 0;
	   }
   }
}
.cart-table {
	.table {
		td, th {
			vertical-align: middle;
			text-align: center;
	   }
		thead th {
			border-width: 1px;
			padding: 15px 10px;
	   }
   }
	.table-hover tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.02);
   }
	.table thead th {
		&.cart-column-image {
			min-width: 145px;
	   }
		&.cart-column-remove {
			min-width: 130px;
	   }
		&.cart-column-quantity {
			min-width: 145px;
	   }
		&.cart-column-productname {
			min-width: 200px;
	   }
		&.cart-column-price, &.cart-column-total {
			min-width: 130px;
	   }
		&.cart-column-addtocart {
			min-width: 160px;
	   }
   }
	.product-image {
		display: inline-block;
		width: 75px;
		padding: 5px 0;
   }
	.product-title {
		display: inline-block;
		font-size: 14px;
		font-weight: 700;
		color: #7e7e7e;
		&:hover {
			color: $green;
	   }
   }
	.total-price {
		display: inline-block;
		font-weight: 700;
   }
	.remove-product {
		color: #7e7e7e;
		display: inline-block;
		width: 26px;
		height: 26px;
		font-size: 20px;
		text-align: center;
		line-height: 18px;
		border: 1px solid #e5e5e5;
		border-radius: 0;
		padding: 0;
		-webkit-transition: all .3s ease-out 0s;
		-o-transition: all .3s ease-out 0s;
		transition: all 0.3s ease-out 0s;
		&:hover {
			color: $white;
			background: $green;
			border-color: $green;
	   }
   }
}
.cart-content-coupon, .cart-content-left {
	margin-top: 30px;
}
.cart-content-coupon {
	> h6 {
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 5px;
   }
	.coupon-form {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-top: 20px;
		button {
			margin-top: 10px;
	   }
		input {
			margin-top: 10px;
			&[type=text] {
				width: 170px;
				margin-right: 10px;
		   }
	   }
   }
}
.cart-content-right {
	margin-top: 30px;
	text-align: right;
	h2 {
		margin-top: -8px;
		display: inline-block;
		border-bottom: 2px solid #454545;
		margin-bottom: 25px;
   }
}
.cart-pricing-table {
	border: none;
	td, th {
		text-align: right;
		border: none;
		padding: 0;
   }
	td {
		color: $green;
		font-weight: 700;
   }
	.cart-total {
		font-size: 24px;
		td, th {
			padding-top: 10px;
	   }
   }
}
@media only screen and (max-width: 767px) {
	.cart-content-right {
		text-align: left;
   }
	.cart-pricing-table {
		td, th {
			text-align: left;
	   }
   }
}
.checkout-info {
	position: relative;
	background-color: #f7f6f7;
	color: #515151;
	border-top: 3px solid #ff5722;
	width: auto;
	padding: 15px;
	margin-bottom: 30px;
	i {
		margin-right: 10px;
		vertical-align: middle;
   }
	a {
		color: #7e7e7e;
		&:hover {
			color: $green;
	   }
   }
}
.checkout-info-collapsebox {
	padding-bottom: 30px;
}
.checkout-info-coupon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: -10px;
	button {
		margin-top: 10px;
   }
	input {
		margin-top: 10px;
		&[type=text] {
			width: 170px;
			margin-right: 10px;
	   }
   }
}
.billing-info {
	margin-top: 50px;
}
.order-infobox {
	background: #f7f7f7;
	padding: 30px 40px 45px;
	.small-title {
		margin-bottom: 20px;
   }
	.table {
		color: #454545;
		border-bottom: none;
		min-width: 280px;
		td, th {
			vertical-align: middle;
			text-align: center;
			padding: 10px 0;
	   }
		tbody td span {
			font-weight: 700;
	   }
		thead th {
			border-top: none;
			border-width: 1px;
	   }
		tfoot {
			td, th {
				font-weight: 700;
				border-bottom: none;
		   }
			.total-price {
				th {
					font-size: 20px;
			   }
				td {
					font-size: 30px;
					color: $green;
			   }
		   }
	   }
   }
}
.different-address {
	margin-top: 40px;
}
.different-address-form-trigger {
	font-size: 18px;
	font-weight: 700;
}
.different-address-form {
	margin-top: 15px;
	display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.order-infobox {
		margin-top: 50px;
   }
}
@media only screen and (max-width: 767px) {
	.different-address-form-trigger {
		font-size: 16px;
   }
	.order-infobox {
		margin-top: 50px;
		.table tfoot .total-price td {
			font-size: 24px;
	   }
   }
}
@media only screen and (max-width: 575px) {
	.order-infobox {
		padding: 20px;
   }
}
.compare-table {
	.table {
		td, th {
			vertical-align: middle;
			text-align: center;
	   }
		thead th {
			border-width: 1px;
			padding: 15px 10px;
	   }
   }
	.table-hover tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.02);
   }
	.table tbody {
		th {
			min-width: 200px;
	   }
		td {
			min-width: 250px;
	   }
   }
}
.compare-pdoduct-image {
	max-width: 200px;
	display: inline-block;
	padding: 20px 0;
	a {
		display: inline-block;
   }
	.ho-button {
		margin-top: 25px;
   }
}
h5.compare-product-name {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 0;
	color: #555;
	a {
		color: #555;
		-webkit-transition-property: color;
		-o-transition-property: color;
		transition-property: color;
		&:hover {
			color: $green;
	   }
   }
}
.myaccount-tab-trigger {
	display: block;
	li {
		display: block;
		+ li {
			margin-top: -1px;
	   }
		a {
			border: 1px solid #444;
			display: block;
			background: #555;
			color: $white;
			text-transform: uppercase;
			font-weight: 600;
			padding: 10px 20px;
			&.active {
				background: $green;
				border-color: $green;
				color: $white;
		   }
			&:hover:not(.active) {
				color: $green;
		   }
	   }
   }
}
.myaccount-tab-content {
	border: 1px solid #e5e5e5;
	padding: 30px;
	-webkit-transition: all .4s ease-in-out 0s;
	-o-transition: all .4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
}
.myaccount-dashboard p {
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
   }
}
.myaccount-orders .table {
	margin-bottom: 0;
}
.myaccount-dashboard p a {
	color: #7e7e7e;
	&:hover {
		color: $green;
   }
}
.myaccount-orders {
	.table .account-order-id {
		color: #7e7e7e;
		&:hover {
			color: $green;
	   }
   }
	.table-hover tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.02);
   }
	.table {
		td, th {
			vertical-align: middle;
			text-align: center;
	   }
   }
}
.myaccount-address .small-title {
	margin-bottom: 15px;
}
.contact-content {
	padding-right: 80px;
	.single-content {
		border-top: 1px solid #e5e5e5;
		padding: 15px 0 15px 65px;
		position: relative;
		&:last-child {
			padding-bottom: 0;
	   }
		&:first-child {
			border-top: none;
	   }
		a {
			color: #7e7e7e;
			&:hover {
				color: $green;
		   }
	   }
   }
	.single-content-icon {
		font-size: 22px;
		position: absolute;
		left: 0;
		top: 15px;
		border: 1px solid #aaa;
		width: 50px;
		line-height: 1;
		text-align: center;
		height: 50px;
		border-radius: 100px;
   }
}
.about-content h5, .blog-details-footer {
	border-bottom: 1px solid #e5e5e5;
}
.contact-content .single-content-icon i {
	line-height: 48px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.contact-content {
		padding-right: 0;
   }
}
@media only screen and (max-width: 575px) {
	.contact-content {
		padding-right: 0;
   }
}
.google-map {
	min-height: 452px;
}
.about-image {
	margin-right: 70px;
	img {
		width: 100%;
   }
}
.about-content {
	background: #f5f5f5;
	padding: 65px 50px;
	margin-left: -150px;
	h2 {
		font-size: 60px;
		color: #666;
		margin: 0 0 10px;
		font-weight: 400;
		span {
			font-size: 100px;
			font-weight: 700;
			color: $green;
			line-height: 1;
			display: block;
	   }
   }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.about-content {
		padding: 40px 50px;
		h2 {
			font-size: 50px;
			span {
				font-size: 75px;
		   }
	   }
   }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.google-map {
		min-height: 380px;
   }
	.about-image {
		margin-right: 0;
   }
	.about-content {
		margin-left: 0;
		padding: 40px 50px;
		h2 {
			font-size: 50px;
			span {
				font-size: 75px;
		   }
	   }
   }
}
@media only screen and (max-width: 767px) {
	.google-map {
		min-height: 320px;
   }
	.about-image {
		margin-right: 0;
   }
	.about-content {
		margin-left: 0;
		padding: 40px 50px;
		h2 {
			font-size: 45px;
			span {
				font-size: 60px;
		   }
	   }
   }
}
@media only screen and (max-width: 575px) {
	.about-content {
		padding: 25px 20px;
		h2 {
			font-size: 38px;
			span {
				font-size: 50px;
		   }
	   }
   }
}


.blog-details {
	.blogitem2 {
		margin-top: 0;
		&:hover .blogitem2-image img {
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
	   }
   }
	.blogitem2-content p {
		line-height: 28px;
   }
}
.blog-details-footer {
	border-top: 1px solid #e5e5e5;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 30px;
}
.blog-details-share, .blog-details-tags {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 10px 0;
}
.blog-details-share h5, .blog-details-tags h5 {
	margin-bottom: 0;
	margin-right: 10px;
	line-height: 26px;
}
.blog-details-share ul, .blog-details-tags ul {
	margin-bottom: 0;
	padding-left: 0;
}
.blog-details-share ul li, .blog-details-tags ul li {
	list-style: none;
	display: inline-block;
}
.blog-details-share ul li a, .blog-details-tags ul li a {
	display: inline-block;
	color: #7e7e7e;
	line-height: 26px;
}
.blog-details-share ul li a:hover {
	color: $green;
}
.blog-details-tags {
	ul li {
		a:hover {
			color: $green;
	   }
		&::after {
			content: ",";
			padding: 0 5px;
	   }
		&:last-child::after {
			display: none;
	   }
   }
	padding-right: 15px;
}
.blog-details-share ul li {
	&:not(:last-child) {
		margin-right: 10px;
   }
	a {
		font-size: 18px;
   }
}
.circle{
	border-radius: 50%;
	padding:15px;
	border:1px solid black;
	vertical-align: center;
	height: 48px;
	width: 48px;
	margin-right: 15px;
	text-align: center;
}
.footer-upper{
	padding: 30px 0;
	border-top: 7px solid $green;
}
.divider{
	border-bottom: 1px solid #e5e5e5;
}
.footer-toparea {
	padding-bottom: 60px;
}
.footer-widget {
	margin-top: 30px;
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		li {
			list-style: none;
	   }
   }
}
@media (max-width: 995px) {
	.footer-widget{
		margin-left: 10px;
	}
	
}
.footer-widget-title {
	text-transform: uppercase;
	margin-bottom: 25px;
	display: block;
	font-size: 14px;
	color: #333;
	font-weight: 700;
	margin-top: 20px;
	line-height: 16px;
}
.footer-widget {
	&.widget-info {
		letter-spacing: 0.4px;
		ul li {
			color: #454545;
			margin-bottom: 10px;
			i {
				color: $green;
				padding-right: 10px;
				font-size: 18px;
				vertical-align: middle;
		   }
			a {
				color: $green;
				&:hover {
					text-decoration: underline;
			   }
		   }
	   }
   }
	&.widget-links ul li a {
		display: block;
		margin-bottom: 10px;
		color: #7e7e7e;
		&:hover {
			color: $green;
	   }
   }
	&.widget-customerservice {
		background: #454545;
		text-align: center;
		padding: 35px 20px;
		border-radius: 7px;
		.footer-widget-title {
			color: $white;
			margin-bottom: 5px;
	   }
		.info {
			padding-bottom: 15px;
			h2, h3, h4, h5, h6 {
				color: #a5a5a5;
				margin-bottom: 5px;
		   }
			h2 a, h3 a, h4 a, h5 a, h6 a {
				color: #ff5722;
		   }
	   }
		.payment {
			padding-top: 20px;
			border-top: 1px solid rgba(255, 255, 255, 0.1);
			h6 {
				margin-bottom: 15px;
				color: $white;
		   }
	   }
   }
}
.footer-copyright {
	border-top: 1px solid #e5e5e5;
	padding: 20px 0;
	.copyright {
		margin-bottom: 0;
		a {
			color: $green;
			font-weight: 500;
	   }
   }
}
#scrollUp {
	right: 30px;
	bottom: 45px;
	height: 45px;
	width: 45px;
	line-height: 1;
	text-align: center;
	background: $green;
	font-size: 22px;
	border-radius: 100%;
	border: 1px solid $green;
	-webkit-animation: crSlideInRight .8s ease-in-out 1 both;
	animation: crSlideInRight .8s ease-in-out 1 both;
	z-index: 1000 !important;
	-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	i {
		line-height: 45px;
   }
}

@media (max-width: 768px) {
	.banner-ads-area,.brand-area, .header-top {
	 display: none;
	}
	.store-area{
		.imgbanner{
			margin-bottom: 10px;
		}
	}
	
}