* {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	color: #282828;
	
	font-size: 13px;
	
	font-size: 14px;
	line-height: 24px;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	font-weight: 400;
}

legend {
	padding: 0;
}
pre, textarea {
	overflow: auto;
}
 img, legend {
	border: 0;
}
a, ins {
	text-decoration: none;
}


article, aside, details, figcaption, figure, footer, header, nav, section, summary {
	display: block;
}
audio, canvas, video {
	display: inline-block;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
a {
	color: $green;
	:active, &:hover {
		color: #ea9629;
	}
}

abbr[title] {
	border-bottom: 1px dotted;
}
b, dt, strong {
	font-weight: 700;
}
mark {
	background: #ff0;
	color: #000;
}
code, kbd, pre, samp {
	font-family: monospace,serif;
	font-size: 14px;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
pre {
	background: #f5f5f5;
	color: #666;
	font-family: monospace;
	font-size: 14px;
	padding: 20px;
	white-space: pre;
	white-space: pre-wrap;
}

blockquote, q {
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
	quotes: none;
}

q {
	&:after, &:before {
			content: "";
			content: none;
 }
}
blockquote {
	font-weight: 300;
	:after, :before {
		content: "";
		content: none;
	}
	blockquote {
			margin-right: 0;
 	}
	cite, small {
			font-size: 14px;
			font-weight: 400;
			text-transform: uppercase;
	 }
	em, i {
			font-style: normal;
			font-weight: 300;
 	}
	b, strong {
			font-weight: 400;
 	}
}

caption, td, th {
	font-weight: 400;
}
small {
	font-size: smaller;
}
sub {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
menu, ol {
	padding: 0 0 0 40px;
}
ul {
	padding: 0 0 0 40px;
	list-style-type: square;
}
nav {
	ol, ul {
			list-style: none;
 }
}
figure, form {
	margin: 0;
}
li > {
	ol, ul {
			margin: 0;
 }
}

img {
	-ms-interpolation-mode: bicubic;
	max-width: 100%;
}

fieldset {
	border: 1px solid silver;
	margin: 0 2px;
	min-width: inherit;
	padding: 0.35em 0.625em 0.75em;
}
legend {
	white-space: normal;
}

input {
	&[type=reset], &[type=submit] {
		-webkit-appearance: button;
		-moz-appearance: button;
		appearance: button;
		cursor: pointer;
 	}
}
button[disabled] {
	cursor: default;
}
input {
	&[disabled] {
			cursor: default;
 }
	&[type=search] {
			-webkit-appearance: textfield;
			-moz-appearance: textfield;
			appearance: textfield;
			padding-right: 2px;
			width: 270px;
			&::-webkit-search-decoration {
					-webkit-appearance: none;
					appearance: none;
		 }
 }
}

textarea {
	vertical-align: top;
	padding: 10px 15px;
}
table {
	border-bottom: 1px solid #ededed;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 14px;
	line-height: 2;
	width: 100%;
}
caption, td, th {
	text-align: left;
}
caption {
	font-size: 16px;
}
th {
	font-weight: 700;
	text-transform: uppercase;
}
td {
	border-top: 1px solid #ededed;
	padding: 6px 10px 6px 0;
}
del {
	color: #333;
}
ins {
	background: #fff9c0;
}
hr {
	background-size: 4px 4px;
	height: 1px;
}

.h1, .h2, .h3, .h4, .h5, .h6, address, blockquote, caption, dd, dl, h1, h2, h3, h4, h5, h6, hr, menu, ol, p, pre, table, ul {
	margin: 0 0 15px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	line-height: 1.3;
	color: #454545;
}
.h1, h1 {
	font-size: 36px;
}
.h2, h2 {
	font-size: 26px;
}
.h3, h3 {
	font-size: 20px;
}
.h4, h4 {
	font-size: 18px;
}
.h5, h5 {
	font-size: 16px;
}
.h6, h6 {
	font-size: 14px;
}


.section-title h3, .slick-slide > div > div, img {
	vertical-align: middle;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.h1, h1 {
			font-size: 32px;
 }
	.h2, h2 {
			font-size: 24px;
 }
	.h3, h3 {
			font-size: 18px;
 }
}
@media only screen and (max-width: 767px) {
	.h1, h1 {
			font-size: 30px;
 }
	.h2, h2 {
			font-size: 22px;
 }
	.h3, h3 {
			font-size: 18px;
 }
}
@media only screen and (max-width: 575px) {
	.h1, h1 {
			font-size: 28px;
 }
}
.h1, .h2, .h3, h1, h2, h3 {
	font-weight: 700;
}
.h4, .h5, h4, h5 {
	font-weight: 600;
}
.h6, h6 {
	font-weight: 500;
}
a {
	&:active, &:focus, &:hover {
			text-decoration: none;
			outline: 0;
 	}
	&:visited {
			color: inherit;
 }
}
button, input, select, textarea {
	background: 0 0;
	border: 1px solid #e5e5e5;
	transition: all .4s ease-out 0s;
	color: #7e7e7e;
	&:hover,
    &:focus,
    &:active {
        text-decoration: none;
        outline: none;
    }

}
button::-webkit-input-placeholder, input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder, button:-moz-placeholder, input:-moz-placeholder, select:-moz-placeholder, textarea:-moz-placeholder, button::-moz-placeholder, input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder, button:-ms-input-placeholder, input:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder {
	color: #7e7e7e;
}

select {
	&:active, &:focus {
			outline: 0;
			border-color: #00b35e;
 }
}
textarea {
	&:active, &:focus {
			outline: 0;
			border-color: #00b35e;
 }
}
input, select, textarea {
	width: 100%;
	font-size: 14px;
	
}

button {
	line-height: 40px;
	height: 40px;
	padding: 0 15px;
	cursor: pointer;
}
button, input {
	&[type=email], &[type=number], &[type=password], &[type=text] {
			line-height: 40px;
			padding: 0 15px;
			border-radius: 0;
 }
}
select {
	line-height: 40px;
	padding: 0 15px;
}
.form-check{
	padding-left: 0; 
}
input {
	&[type=checkbox], &[type=radio] {
			visibility: hidden;
			opacity: 0;
			display: inline-block;
			vertical-align: middle;
			width: 0;
 }
	&[type=checkbox] ~ label, &[type=radio] ~ label {
			position: relative;
			padding-left: 24px;
 }
	&[type=checkbox] {
			~ label::before {
					content: "";
					position: absolute;
					left: 0;
					top: 50%;
					border: 1px solid #e5e5e5;
					height: 15px;
					width: 15px;
					margin-top: -8px;
					line-height: .5;
					font-size: 28px;
		 }
			&:checked ~ label {
					color: #00b35e;
		 }
 }
	&[type=radio]:checked ~ label {
			color: #00b35e;
 }
	&[type=checkbox]:checked ~ label::before {
			content: "\f3ff";
			font-family: "Ionicons";
			color: #00b35e;
			border-color: #00b35e;
 }
	&[type=radio] {
			~ label::before {
					content: "";
					position: absolute;
					left: 0;
					top: 50%;
					border: 2px solid #e5e5e5;
					border-radius: 100px;
					height: 15px;
					width: 15px;
					margin-top: -8px;
					line-height: .5;
					font-size: 28px;
		 }
			&:checked ~ label::before {
					color: #00b35e;
					border-color: #00b35e;
		 }
 }
}
blockquote {
	font-size: 16px;
	color: #454545;
	border: 1px solid #e5e5e5;
	border-left: 4px solid #e5e5e5;
	padding: 20px 30px;
}
.hidden {
	display: none;
}

.visuallyhidden.focusable {
	&:active, &:focus {
			clip: auto;
			height: auto;
			margin: 0;
			overflow: visible;
			position: static;
			width: auto;
 }
}
.h-100, .youtube-bg {
	height: 100%;
}
.background-video-holder, .wrapper {
	position: relative;
}
.invisible {
	visibility: hidden;
}
.clearfix {
	&:after, &:before {
			content: " ";
			display: table;
 }
	&:after {
			clear: both;
 }
}
.color-theme {
	color: #00b35e;
}
.color-light {
	color: #999;
}
.color-dark {
	color: #333;
}
.color-black {
	color: #000;
}
#scrollUp {
	color: #fff;
}
.bg-dark {
	color: #fff;
	h1, h2, h3, h4, h5, h6, p {
			color: #fff;
 }
}
.bg-dark-light {
	color: #fff;
	h1, h2, h3, h4, h5, h6, p {
			color: #fff;
 }
}
.bg-secondary {
	color: #fff;
	h1, h2, h3, h4, h5, h6, p {
			color: #fff;
 }
}

.color-white {
	color: #fff;
}

.text-italic {
	font-style: italic;
}
.text-normal {
	font-style: normal;
}
.text-underline {
	font-style: underline;
}
.body-font, .heading-font {
	font-family: Roboto, sans-serif;
}
.fullscreen {
	min-height: 100vh;
	width: 100%;
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.section-padding-xlg {
			padding: 100px 0;
 }
	.section-padding-lg {
			padding: 90px 0;
 }
	.section-padding-md {
			padding: 80px 0;
 }
	.section-padding-sm {
			padding: 60px 0;
 }
	.section-padding-top-xlg {
			padding-top: 100px;
 }
	.section-padding-top-lg {
			padding-top: 90px;
 }
	.section-padding-top-md {
			padding-top: 80px;
 }
	.section-padding-top-sm {
			padding-top: 60px;
 }
	.section-padding-bottom-xlg {
			padding-bottom: 100px;
 }
	.section-padding-bottom-lg {
			padding-bottom: 90px;
 }
	.section-padding-bottom-md {
			padding-bottom: 80px;
 }
	.section-padding-bottom-sm {
			padding-bottom: 60px;
 }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.section-padding-xlg {
			padding: 110px 0;
 }
	.section-padding-lg {
			padding: 100px 0;
 }
	.section-padding-md {
			padding: 80px 0;
 }
	.section-padding-sm {
			padding: 60px 0;
 }
	.section-padding-top-xlg {
			padding-top: 110px;
 }
	.section-padding-top-lg {
			padding-top: 100px;
 }
	.section-padding-top-md {
			padding-top: 80px;
 }
	.section-padding-top-sm {
			padding-top: 60px;
 }
	.section-padding-bottom-xlg {
			padding-bottom: 110px;
 }
	.section-padding-bottom-lg {
			padding-bottom: 100px;
 }
	.section-padding-bottom-md {
			padding-bottom: 80px;
 }
	.section-padding-bottom-sm {
			padding-bottom: 60px;
 }
}
@media only screen and (max-width: 767px) {
	.section-padding-xlg {
			padding: 75px 0;
 }
	.section-padding-lg {
			padding: 65px 0;
 }
	.section-padding-md {
			padding: 60px 0;
 }
	.section-padding-sm {
			padding: 55px 0;
 }
	.section-padding-top-xlg {
			padding-top: 75px;
 }
	.section-padding-top-lg {
			padding-top: 65px;
 }
	.section-padding-top-md {
			padding-top: 60px;
 }
	.section-padding-top-sm {
			padding-top: 55px;
 }
	.section-padding-bottom-xlg {
			padding-bottom: 75px;
 }
	.section-padding-bottom-lg {
			padding-bottom: 65px;
 }
	.section-padding-bottom-md {
			padding-bottom: 60px;
 }
	.section-padding-bottom-sm {
			padding-bottom: 55px;
 }
	#scrollUp {
			display: none !important;
 }
}
.pt-30, .ptb-30 {
	padding-top: 30px;
}
.pb-30, .ptb-30 {
	padding-bottom: 30px;
}
.cr-border {
	border-width: 1px;
}
.cr-border-top {
	border-width: 1px 0 0;
}
.cr-border-right {
	border-width: 0 1px 0 0;
}
.cr-border-bottom {
	border-width: 0 0 1px;
}
.cr-border-left {
	border-width: 0 0 0 1px;
}
[data-black-overlay], [data-overlay], [data-white-overlay] {
	position: relative;
}
[data-black-overlay] > {
	*, div {
			position: relative;
			z-index: 2;
 }
}
[data-overlay] > {
	*, div {
			position: relative;
			z-index: 2;
 }
}
[data-white-overlay] > {
	*, div {
			position: relative;
			z-index: 2;
 }
}
[data-black-overlay]:before, [data-overlay]:before, [data-white-overlay]:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
}
[data-overlay]:before {
	background-color: #00b35e;
}
[data-black-overlay]:before {
	background-color: #000;
}
[data-white-overlay]:before {
	background-color: #fff;
}
[data-black-overlay="1"]:before, [data-overlay="1"]:before, [data-white-overlay="1"]:before {
	opacity: 0.1;
}
[data-black-overlay="2"]:before, [data-overlay="2"]:before, [data-white-overlay="2"]:before {
	opacity: 0.2;
}
[data-black-overlay="3"]:before, [data-overlay="3"]:before, [data-white-overlay="3"]:before {
	opacity: 0.3;
}
[data-black-overlay="4"]:before, [data-overlay="4"]:before, [data-white-overlay="4"]:before {
	opacity: 0.4;
}
[data-black-overlay="5"]:before, [data-overlay="5"]:before, [data-white-overlay="5"]:before {
	opacity: 0.5;
}
[data-black-overlay="6"]:before, [data-overlay="6"]:before, [data-white-overlay="6"]:before {
	opacity: 0.6;
}
[data-black-overlay="7"]:before, [data-overlay="7"]:before, [data-white-overlay="7"]:before {
	opacity: 0.7;
}
[data-black-overlay="8"]:before, [data-overlay="8"]:before, [data-white-overlay="8"]:before {
	opacity: 0.8;
}
[data-black-overlay="9"]:before, [data-overlay="9"]:before, [data-white-overlay="9"]:before {
	opacity: 0.9;
}
[data-black-overlay="10"]:before, [data-overlay="10"]:before, [data-white-overlay="10"]:before {
	opacity: 1;
}

.bg-theme{
    background:#0B88EE!important
}
.bg-secondary{
    background:#FFE11B!important
}
.bg-light{
    background:#999!important
}
.bg-grey{
    background:#f4f4f4!important
}
.bg-dark{
    background:#2a2a2a!important
}
.bg-dark-light{
    background:#323232!important
}
.bg-black{
    background:#000!important
}
.bg-white{
    background:#fff!important
}

.is-active{
	visibility: visible !important;
}