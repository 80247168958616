

.hamburger-mobile-menu {
    position: absolute;
    z-index: 10012;
    /* padding: 10px; */
    font-size: 18px;
    line-height: 38px;
    height: 38px;
    width: 38px;
    text-align: center;
    left: 5px;
    top: 10px;
    color: $green;
    display: none;
    border: 1px solid #eee;
    span {
        color: $green;
    }
}

.mobile-menu-container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    border: 1px solid #e5e5e5;
    border-width: 0 1px 0 0;
    background-color: #F2F2F2;
    width: 100%;
    max-width: 250px;
    overflow-y: auto;
    visibility: hidden;
    .header-account {
        min-height: 80px;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
    }
    .nano-pane {
        background: rgba(255, 255, 255, 0);
        font-size: 15px;
    }
    .menu-list {
        border-right: 1px solid #CCC;
    }
    .menu-list li a {
        padding-left: 20px;
    }
    .menu-list {
        margin: 0;
        padding: 0;
        list-style: none;
        li:first-child {
            border-top: 0;
        }
        li {
            display: block;
            padding: 0;
            margin: 0;
            border: 0;
            border-top: 1px solid #fcfcfc;
            border-bottom: 1px solid #e5e5e5;
            position: relative;
            text-decoration: none;
            &.arrow-down:after {
                content: '';
                width: 7px;
                height: 7px;
                position: absolute;
                right: 10px;
                top: 15px;
                border-top: 1px solid #bababa;
                border-left: 1px solid #bababa;
                -webkit-transform: rotate(45deg);
                -webkit-animation: arrow-slide .5s 0s ease both;
            }
            &.init-arrow-down:after {
                content: '';
                width: 7px;
                height: 7px;
                position: absolute;
                right: 10px;
                top: 15px;
                border-right: 1px solid #bababa;
                border-bottom: 1px solid #bababa;
                -webkit-transform: rotate(45deg);
            }
            &.arrow-up:after {
                content: '';
                width: 7px;
                height: 7px;
                position: absolute;
                right: 10px;
                top: 15px;
                border-right: 1px solid #bababa;
                border-bottom: 1px solid #bababa;
                -webkit-transform: rotate(45deg);
                -webkit-animation: arrow-slide1 .5s 0s ease both;
            }
            &.init-arrow-up:after {
                content: '';
                width: 7px;
                height: 7px;
                position: absolute;
                right: 10px;
                top: 15px;
                border-top: 1px solid #bababa;
                border-left: 1px solid #bababa;
                -webkit-transform: rotate(45deg);
            }
            &.active {
                background-color: #fff;
            }
            >a {
                display: block;
                height: 38px;
                line-height: 36px;
                padding: 0 16px 0 7px;
                background-color: #f9f9f9;
                color: #585858;
                text-shadow: none !important;
                font-size: 13px;
                text-decoration: none;
            }
        }
    }
    @-webkit-keyframes arrow-slide {
        0% {}
        100% {
            -webkit-transform: rotate(225deg);
            z-index: 3;
        }
    }
    @-webkit-keyframes arrow-slide1 {
        0% {}
        100% {
            -webkit-transform: rotate(225deg);
            z-index: 3;
        }
    }
    .gw-open>a {
        outline: 0;
    }
    .menu-list {
        >li.gw-open {
            border-bottom-color: #e5e5e5;
            >a {
                background-color: #fafafa;
                color: #1963aa;
            }
        }
        .gw-open>a {
            background-color: #fafafa;
            &:hover,
            &:focus {
                background-color: #fafafa;
            }
        }
    }
    .menu-mobile-nav .gw-open>a {
        background-color: #eee;
        border-color: #428bca;
        &:hover,
        &:focus {
            background-color: #eee;
            border-color: #428bca;
        }
    }
    .menu-list {
        >li {
            &.active>a {
                background-color: #fff;
                color: #dd4814;
                font-weight: bold;
                font-size: 13px;
                &:hover,
                &:focus,
                &:active {
                    background-color: #fff;
                    color: #dd4814;
                    font-weight: bold;
                    font-size: 13px;
                }
            }
            >a {
                margin: 0;
            }
        }
        .menu-mobile-nav-header {
            margin: 0;
        }
        >li.active {
            >a {
                >[class*="icon-"] {
                    font-weight: normal;
                }
                &:hover:before {
                    display: none;
                }
            }
            &:before {
                display: inline-block;
                content: "";
                position: absolute;
                right: -2px;
                top: -1px;
                bottom: 0;
                z-index: 1;
                border: 2px solid #dd4814;
                border-width: 0 2px 0 0;
            }
        }
        li {
            &.gw-open>a:after {
                display: none;
            }
            .gw-submenu {
                overflow: hidden;
            }
            &.active {
                &.gw-open {
                    >.gw-submenu>li.active.gw-open>a.dropdown-toggle:after {
                        display: none;
                    }
                    >.gw-submenu>li.active>a:after {
                        display: block;
                    }
                }
                >.gw-submenu>li.active>a:after {
                    display: none;
                }
            }
        }
        >li {
            a {
                >.gw-arrow {
                    display: inline-block;
                    width: 14px !important;
                    height: 14px;
                    line-height: 14px;
                    text-shadow: none;
                    font-size: 18px;
                    position: absolute;
                    right: 11px;
                    top: 11px;
                    padding: 0;
                    color: #666;
                }
                &:hover>.gw-arrow {
                    color: #1963aa;
                }
            }
            &.active>a>.gw-arrow,
            &.gw-open>a>.gw-arrow {
                color: #1963aa;
            }
            >a>[class*="icon-"]:first-child {
                display: inline-block;
                vertical-align: middle;
                min-width: 30px;
                text-align: center;
                font-size: 18px;
                font-weight: normal;
                margin-right: 2px;
            }
            .gw-submenu {
                font-size: 13px;
                display: none;
                list-style: none;
                margin: 0;
                padding: 0;
                position: relative;
                background-color: #fff;
                border-top: 1px solid #e5e5e5;
                >li {
                    margin-left: 0;
                    position: relative;
                    >a {
                        display: block;
                        position: relative;
                        color: #616161;
                        padding: 7px 0 9px 43px;
                        margin: 0;
                        border-top: 1px dotted #e4e4e4;
                        font-size: 14px;
                        text-decoration: none;
                        &:focus {
                            text-decoration: none;
                        }
                        &:hover {
                            text-decoration: none;
                            color: #dd4814;
                            background-color: rgba(25, 25, 50, 0.1);
                        }
                    }
                    &.active {
                        &:after {
                            display: inline-block;
                            content: "";
                            position: absolute;
                            right: -1px;
                            top: -1px;
                            bottom: 0;
                            z-index: 1;
                            border: 2px solid #dd4814;
                        }
                        >a {
                            color: #dd4814;
                        }
                    }
                    a>[class*="icon-"]:first-child {
                        display: none;
                        font-size: 12px;
                        font-weight: normal;
                        width: 18px;
                        height: auto;
                        line-height: 12px;
                        text-align: center;
                        position: absolute;
                        left: 10px;
                        top: 11px;
                        z-index: 1;
                        background-color: #FFF;
                    }
                    &.active>a>[class*="icon-"]:first-child,
                    &:hover>a>[class*="icon-"]:first-child {
                        display: inline-block;
                    }
                    &.active>a>[class*="icon-"]:first-child {
                        color: #c86139;
                    }
                }
            }
            >.gw-submenu>li:first-child>a {
                border-top: 0px;
            }
        }
    }
    .menu-mobile-nav-tabs li[class*=" icon-"],
    .nav-tabs li[class^="icon-"] {
        width: 1.25em;
        display: inline-block;
        text-align: center;
    }
}