@import 'https://fonts.googleapis.com/css?family=Lato';
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

body {
  color: #282828;
  font-size: 13px;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400; }

legend {
  padding: 0; }

pre, textarea {
  overflow: auto; }

img, legend {
  border: 0; }

a, ins {
  text-decoration: none; }

article, aside, details, figcaption, figure, footer, header, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

a {
  color: #42b549; }
  a :active, a:hover {
    color: #ea9629; }

abbr[title] {
  border-bottom: 1px dotted; }

b, dt, strong {
  font-weight: 700; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace,serif;
  font-size: 14px;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

pre {
  background: #f5f5f5;
  color: #666;
  font-family: monospace;
  font-size: 14px;
  padding: 20px;
  white-space: pre;
  white-space: pre-wrap; }

blockquote, q {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none; }

q:after, q:before {
  content: "";
  content: none; }

blockquote {
  font-weight: 300; }
  blockquote :after, blockquote :before {
    content: "";
    content: none; }
  blockquote blockquote {
    margin-right: 0; }
  blockquote cite, blockquote small {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase; }
  blockquote em, blockquote i {
    font-style: normal;
    font-weight: 300; }
  blockquote b, blockquote strong {
    font-weight: 400; }

caption, td, th {
  font-weight: 400; }

small {
  font-size: smaller; }

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em; }

sub {
  bottom: -0.25em; }

menu, ol {
  padding: 0 0 0 40px; }

ul {
  padding: 0 0 0 40px;
  list-style-type: square; }

nav ol, nav ul {
  list-style: none; }

figure, form {
  margin: 0; }

li > ol, li > ul {
  margin: 0; }

img {
  -ms-interpolation-mode: bicubic;
  max-width: 100%; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em; }

legend {
  white-space: normal; }

input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer; }

button[disabled] {
  cursor: default; }

input[disabled] {
  cursor: default; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  width: 270px; }
  input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none; }

textarea {
  vertical-align: top;
  padding: 10px 15px; }

table {
  border-bottom: 1px solid #ededed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
  line-height: 2;
  width: 100%; }

caption, td, th {
  text-align: left; }

caption {
  font-size: 16px; }

th {
  font-weight: 700;
  text-transform: uppercase; }

td {
  border-top: 1px solid #ededed;
  padding: 6px 10px 6px 0; }

del {
  color: #333; }

ins {
  background: #fff9c0; }

hr {
  background-size: 4px 4px;
  height: 1px; }

.h1, .h2, .h3, .h4, .h5, .h6, address, blockquote, caption, dd, dl, h1, h2, h3, h4, h5, h6, hr, menu, ol, p, pre, table, ul {
  margin: 0 0 15px; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  color: #454545; }

.h1, h1 {
  font-size: 36px; }

.h2, h2 {
  font-size: 26px; }

.h3, h3 {
  font-size: 20px; }

.h4, h4 {
  font-size: 18px; }

.h5, h5 {
  font-size: 16px; }

.h6, h6 {
  font-size: 14px; }

.section-title h3, .slick-slide > div > div, img {
  vertical-align: middle; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h1, h1 {
    font-size: 32px; }
  .h2, h2 {
    font-size: 24px; }
  .h3, h3 {
    font-size: 18px; } }

@media only screen and (max-width: 767px) {
  .h1, h1 {
    font-size: 30px; }
  .h2, h2 {
    font-size: 22px; }
  .h3, h3 {
    font-size: 18px; } }

@media only screen and (max-width: 575px) {
  .h1, h1 {
    font-size: 28px; } }

.h1, .h2, .h3, h1, h2, h3 {
  font-weight: 700; }

.h4, .h5, h4, h5 {
  font-weight: 600; }

.h6, h6 {
  font-weight: 500; }

a:active, a:focus, a:hover {
  text-decoration: none;
  outline: 0; }

a:visited {
  color: inherit; }

button, input, select, textarea {
  background: 0 0;
  border: 1px solid #e5e5e5;
  transition: all .4s ease-out 0s;
  color: #7e7e7e; }
  button:hover, button:focus, button:active, input:hover, input:focus, input:active, select:hover, select:focus, select:active, textarea:hover, textarea:focus, textarea:active {
    text-decoration: none;
    outline: none; }

button::-webkit-input-placeholder, input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder, button:-moz-placeholder, input:-moz-placeholder, select:-moz-placeholder, textarea:-moz-placeholder, button::-moz-placeholder, input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder, button:-ms-input-placeholder, input:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #7e7e7e; }

select:active, select:focus {
  outline: 0;
  border-color: #00b35e; }

textarea:active, textarea:focus {
  outline: 0;
  border-color: #00b35e; }

input, select, textarea {
  width: 100%;
  font-size: 14px; }

button {
  line-height: 40px;
  height: 40px;
  padding: 0 15px;
  cursor: pointer; }

button[type=email], button[type=number], button[type=password], button[type=text], input[type=email], input[type=number], input[type=password], input[type=text] {
  line-height: 40px;
  padding: 0 15px;
  border-radius: 0; }

select {
  line-height: 40px;
  padding: 0 15px; }

.form-check {
  padding-left: 0; }

input[type=checkbox], input[type=radio] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0; }

input[type=checkbox] ~ label, input[type=radio] ~ label {
  position: relative;
  padding-left: 24px; }

input[type=checkbox] ~ label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border: 1px solid #e5e5e5;
  height: 15px;
  width: 15px;
  margin-top: -8px;
  line-height: .5;
  font-size: 28px; }

input[type=checkbox]:checked ~ label {
  color: #00b35e; }

input[type=radio]:checked ~ label {
  color: #00b35e; }

input[type=checkbox]:checked ~ label::before {
  content: "\f3ff";
  font-family: "Ionicons";
  color: #00b35e;
  border-color: #00b35e; }

input[type=radio] ~ label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border: 2px solid #e5e5e5;
  border-radius: 100px;
  height: 15px;
  width: 15px;
  margin-top: -8px;
  line-height: .5;
  font-size: 28px; }

input[type=radio]:checked ~ label::before {
  color: #00b35e;
  border-color: #00b35e; }

blockquote {
  font-size: 16px;
  color: #454545;
  border: 1px solid #e5e5e5;
  border-left: 4px solid #e5e5e5;
  padding: 20px 30px; }

.hidden {
  display: none; }

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.h-100, .youtube-bg {
  height: 100%; }

.background-video-holder, .wrapper {
  position: relative; }

.invisible {
  visibility: hidden; }

.clearfix:after, .clearfix:before {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.color-theme {
  color: #00b35e; }

.color-light {
  color: #999; }

.color-dark {
  color: #333; }

.color-black {
  color: #000; }

#scrollUp {
  color: #fff; }

.bg-dark {
  color: #fff; }
  .bg-dark h1, .bg-dark h2, .bg-dark h3, .bg-dark h4, .bg-dark h5, .bg-dark h6, .bg-dark p {
    color: #fff; }

.bg-dark-light {
  color: #fff; }
  .bg-dark-light h1, .bg-dark-light h2, .bg-dark-light h3, .bg-dark-light h4, .bg-dark-light h5, .bg-dark-light h6, .bg-dark-light p {
    color: #fff; }

.bg-secondary {
  color: #fff; }
  .bg-secondary h1, .bg-secondary h2, .bg-secondary h3, .bg-secondary h4, .bg-secondary h5, .bg-secondary h6, .bg-secondary p {
    color: #fff; }

.color-white {
  color: #fff; }

.text-italic {
  font-style: italic; }

.text-normal {
  font-style: normal; }

.text-underline {
  font-style: underline; }

.body-font, .heading-font {
  font-family: Roboto, sans-serif; }

.fullscreen {
  min-height: 100vh;
  width: 100%; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-xlg {
    padding: 100px 0; }
  .section-padding-lg {
    padding: 90px 0; }
  .section-padding-md {
    padding: 80px 0; }
  .section-padding-sm {
    padding: 60px 0; }
  .section-padding-top-xlg {
    padding-top: 100px; }
  .section-padding-top-lg {
    padding-top: 90px; }
  .section-padding-top-md {
    padding-top: 80px; }
  .section-padding-top-sm {
    padding-top: 60px; }
  .section-padding-bottom-xlg {
    padding-bottom: 100px; }
  .section-padding-bottom-lg {
    padding-bottom: 90px; }
  .section-padding-bottom-md {
    padding-bottom: 80px; }
  .section-padding-bottom-sm {
    padding-bottom: 60px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-xlg {
    padding: 110px 0; }
  .section-padding-lg {
    padding: 100px 0; }
  .section-padding-md {
    padding: 80px 0; }
  .section-padding-sm {
    padding: 60px 0; }
  .section-padding-top-xlg {
    padding-top: 110px; }
  .section-padding-top-lg {
    padding-top: 100px; }
  .section-padding-top-md {
    padding-top: 80px; }
  .section-padding-top-sm {
    padding-top: 60px; }
  .section-padding-bottom-xlg {
    padding-bottom: 110px; }
  .section-padding-bottom-lg {
    padding-bottom: 100px; }
  .section-padding-bottom-md {
    padding-bottom: 80px; }
  .section-padding-bottom-sm {
    padding-bottom: 60px; } }

@media only screen and (max-width: 767px) {
  .section-padding-xlg {
    padding: 75px 0; }
  .section-padding-lg {
    padding: 65px 0; }
  .section-padding-md {
    padding: 60px 0; }
  .section-padding-sm {
    padding: 55px 0; }
  .section-padding-top-xlg {
    padding-top: 75px; }
  .section-padding-top-lg {
    padding-top: 65px; }
  .section-padding-top-md {
    padding-top: 60px; }
  .section-padding-top-sm {
    padding-top: 55px; }
  .section-padding-bottom-xlg {
    padding-bottom: 75px; }
  .section-padding-bottom-lg {
    padding-bottom: 65px; }
  .section-padding-bottom-md {
    padding-bottom: 60px; }
  .section-padding-bottom-sm {
    padding-bottom: 55px; }
  #scrollUp {
    display: none !important; } }

.pt-30, .ptb-30 {
  padding-top: 30px; }

.pb-30, .ptb-30 {
  padding-bottom: 30px; }

.cr-border {
  border-width: 1px; }

.cr-border-top {
  border-width: 1px 0 0; }

.cr-border-right {
  border-width: 0 1px 0 0; }

.cr-border-bottom {
  border-width: 0 0 1px; }

.cr-border-left {
  border-width: 0 0 0 1px; }

[data-black-overlay], [data-overlay], [data-white-overlay] {
  position: relative; }

[data-black-overlay] > *, [data-black-overlay] > div {
  position: relative;
  z-index: 2; }

[data-overlay] > *, [data-overlay] > div {
  position: relative;
  z-index: 2; }

[data-white-overlay] > *, [data-white-overlay] > div {
  position: relative;
  z-index: 2; }

[data-black-overlay]:before, [data-overlay]:before, [data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2; }

[data-overlay]:before {
  background-color: #00b35e; }

[data-black-overlay]:before {
  background-color: #000; }

[data-white-overlay]:before {
  background-color: #fff; }

[data-black-overlay="1"]:before, [data-overlay="1"]:before, [data-white-overlay="1"]:before {
  opacity: 0.1; }

[data-black-overlay="2"]:before, [data-overlay="2"]:before, [data-white-overlay="2"]:before {
  opacity: 0.2; }

[data-black-overlay="3"]:before, [data-overlay="3"]:before, [data-white-overlay="3"]:before {
  opacity: 0.3; }

[data-black-overlay="4"]:before, [data-overlay="4"]:before, [data-white-overlay="4"]:before {
  opacity: 0.4; }

[data-black-overlay="5"]:before, [data-overlay="5"]:before, [data-white-overlay="5"]:before {
  opacity: 0.5; }

[data-black-overlay="6"]:before, [data-overlay="6"]:before, [data-white-overlay="6"]:before {
  opacity: 0.6; }

[data-black-overlay="7"]:before, [data-overlay="7"]:before, [data-white-overlay="7"]:before {
  opacity: 0.7; }

[data-black-overlay="8"]:before, [data-overlay="8"]:before, [data-white-overlay="8"]:before {
  opacity: 0.8; }

[data-black-overlay="9"]:before, [data-overlay="9"]:before, [data-white-overlay="9"]:before {
  opacity: 0.9; }

[data-black-overlay="10"]:before, [data-overlay="10"]:before, [data-white-overlay="10"]:before {
  opacity: 1; }

.bg-theme {
  background: #0B88EE !important; }

.bg-secondary {
  background: #FFE11B !important; }

.bg-light {
  background: #999 !important; }

.bg-grey {
  background: #f4f4f4 !important; }

.bg-dark {
  background: #2a2a2a !important; }

.bg-dark-light {
  background: #323232 !important; }

.bg-black {
  background: #000 !important; }

.bg-white {
  background: #fff !important; }

.is-active {
  visibility: visible !important; }

/************************* Basic margin padding *************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0; }

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0; }

/************************* Margin top *************************/
.mt-0 {
  margin-top: 0; }

.mt-10 {
  margin-top: 10px; }

.mt-12 {
  margin-top: 12px; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-35 {
  margin-top: 35px; }

.mt-37 {
  margin-top: 37px; }

.mt-40 {
  margin-top: 40px; }

.mt-50 {
  margin-top: 50px; }

.mt-55 {
  margin-top: 55px; }

.mt-60 {
  margin-top: 60px; }

.mt-70 {
  margin-top: 70px; }

.mt-80 {
  margin-top: 80px; }

.mt-90 {
  margin-top: 90px; }

/************************* Margin right *************************/
.mr-0 {
  margin-right: 0px; }

.mr-10 {
  margin-right: 10px; }

.mr-12 {
  margin-right: 12px; }

.mr-15 {
  margin-right: 15px; }

.mr-20 {
  margin-right: 20px; }

.mr-30 {
  margin-right: 30px; }

.mr-35 {
  margin-right: 35px; }

.mr-40 {
  margin-right: 40px; }

.mr-50 {
  margin-right: 50px; }

.mr-60 {
  margin-right: 60px; }

.mr-70 {
  margin-right: 70px; }

.mr-80 {
  margin-right: 80px; }

.mr-90 {
  margin-right: 90px; }

/************************* Margin bottom *************************/
.mb-0 {
  margin-bottom: 0; }

.mb-10 {
  margin-bottom: 10px; }

.mb-12 {
  margin-bottom: 12px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-25 {
  margin-bottom: 25px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-35 {
  margin-bottom: 35px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-45 {
  margin-bottom: 45px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-55 {
  margin-bottom: 55px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-65 {
  margin-bottom: 65px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-90 {
  margin-bottom: 90px; }

.mb-100 {
  margin-bottom: 100px; }

.mb-110 {
  margin-bottom: 110px; }

.mb-120 {
  margin-bottom: 120px; }

.mb-130 {
  margin-bottom: 130px; }

.mb-140 {
  margin-bottom: 140px; }

.mb-150 {
  margin-bottom: 150px; }

/************************* Margin left *************************/
.ml-0 {
  margin-left: 0; }

.ml-10 {
  margin-left: 10px; }

.ml-12 {
  margin-left: 12px; }

.ml-15 {
  margin-left: 15px; }

.ml-20 {
  margin-left: 20px; }

.ml-30 {
  margin-left: 30px; }

.ml-40 {
  margin-left: 40px; }

.ml-50 {
  margin-left: 50px; }

.ml-60 {
  margin-left: 60px; }

.ml-70 {
  margin-left: 70px; }

.ml-80 {
  margin-left: 80px; }

.ml-90 {
  margin-left: 90px; }

.ml-100 {
  margin-left: 100px; }

.ml-110 {
  margin-left: 110px; }

.ml-120 {
  margin-left: 120px; }

.ml-125 {
  margin-left: 125px; }

.ml-130 {
  margin-left: 130px; }

.ml-140 {
  margin-left: 140px; }

.ml-150 {
  margin-left: 150px; }

/************************* Padding top *************************/
.pt-0 {
  padding-top: 0; }

.pt-10 {
  padding-top: 10px; }

.pt-15 {
  padding-top: 15px; }

.pt-20 {
  padding-top: 20px; }

.pt-30 {
  padding-top: 30px; }

.pt-37 {
  padding-top: 37px; }

.pt-40 {
  padding-top: 40px; }

.pt-50 {
  padding-top: 50px; }

.pt-55 {
  padding-top: 55px; }

.pt-60 {
  padding-top: 60px; }

.pt-65 {
  padding-top: 65px; }

.pt-70 {
  padding-top: 70px; }

.pt-75 {
  padding-top: 75px; }

.pt-80 {
  padding-top: 80px; }

.pt-85 {
  padding-top: 85px; }

.pt-95 {
  padding-top: 95px; }

.pt-90 {
  padding-top: 90px; }

.pt-97 {
  padding-top: 97px; }

.pt-100 {
  padding-top: 100px; }

.pt-95 {
  padding-top: 95px; }

.pt-105 {
  padding-top: 105px; }

.pt-110 {
  padding-top: 110px; }

.pt-115 {
  padding-top: 115px; }

.pt-120 {
  padding-top: 120px; }

.pt-125 {
  padding-top: 125px; }

.pt-130 {
  padding-top: 130px; }

.pt-135 {
  padding-top: 135px; }

.pt-140 {
  padding-top: 140px; }

.pt-150 {
  padding-top: 150px; }

.pt-155 {
  padding-top: 155px; }

.pt-180 {
  padding-top: 180px; }

.pt-205 {
  padding-top: 205px; }

.pt-365 {
  padding-top: 365px; }

.pt-370 {
  padding-top: 370px; }

.pt-380 {
  padding-top: 380px; }

/************************* Padding right *************************/
.pr-0 {
  padding-right: 0; }

.pr-10 {
  padding-right: 10px; }

.pr-15 {
  padding-right: 15px; }

.pr-20 {
  padding-right: 20px; }

.pr-30 {
  padding-right: 30px; }

.pr-40 {
  padding-right: 40px; }

.pr-50 {
  padding-right: 50px; }

.pr-60 {
  padding-right: 60px; }

.pr-70 {
  padding-right: 70px; }

.pr-80 {
  padding-right: 80px; }

.pr-90 {
  padding-right: 90px; }

.pr-100 {
  padding-right: 100px; }

.pr-110 {
  padding-right: 110px; }

.pr-120 {
  padding-right: 120px; }

.pr-130 {
  padding-right: 130px; }

.pr-140 {
  padding-right: 140px; }

.pr-155 {
  padding-right: 155px;
  padding-right: 155px; }

.pr-180 {
  padding-right: 180px; }

.pr-195 {
  padding-right: 195px; }

/************************* Padding bottom *************************/
.pb-0 {
  padding-bottom: 0; }

.pb-10 {
  padding-bottom: 10px; }

.pb-15 {
  padding-bottom: 15px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-25 {
  padding-bottom: 25px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-35 {
  padding-bottom: 35px; }

.pb-40 {
  padding-bottom: 40px; }

.pb-45 {
  padding-bottom: 45px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-55 {
  padding-bottom: 55px; }

.pb-60 {
  padding-bottom: 60px; }

.pb-65 {
  padding-bottom: 65px; }

.pb-70 {
  padding-bottom: 70px; }

.pb-75 {
  padding-bottom: 75px; }

.pb-80 {
  padding-bottom: 80px; }

.pb-85 {
  padding-bottom: 85px; }

.pb-90 {
  padding-bottom: 90px; }

.pb-95 {
  padding-bottom: 95px; }

.pb-97 {
  padding-bottom: 97px; }

.pb-100 {
  padding-bottom: 100px; }

.pb-105 {
  padding-bottom: 105px; }

.pb-107 {
  padding-bottom: 107px; }

.pb-110 {
  padding-bottom: 110px; }

.pb-115 {
  padding-bottom: 115px; }

.pb-120 {
  padding-bottom: 120px; }

.pb-125 {
  padding-bottom: 125px; }

.pb-130 {
  padding-bottom: 130px; }

.pb-140 {
  padding-bottom: 140px; }

.pb-145 {
  padding-bottom: 145px; }

.pb-150 {
  padding-bottom: 150px; }

.pb-178 {
  padding-bottom: 178px; }

.pb-180 {
  padding-bottom: 180px; }

.pb-280 {
  padding-bottom: 280px; }

.pb-210 {
  padding-bottom: 210px; }

.pb-285 {
  padding-bottom: 285px; }

.pb-290 {
  padding-bottom: 290px; }

/************************* Padding left *************************/
.pl-0 {
  padding-left: 0; }

.pl-10 {
  padding-left: 10px; }

.pl-15 {
  padding-left: 15px; }

.pl-20 {
  padding-left: 20px; }

.pl-30 {
  padding-left: 30px; }

.pl-40 {
  padding-left: 40px; }

.pl-50 {
  padding-left: 50px; }

.pl-60 {
  padding-left: 60px; }

.pl-70 {
  padding-left: 70px; }

.pl-80 {
  padding-left: 80px; }

.pl-90 {
  padding-left: 90px; }

.pl-100 {
  padding-left: 100px; }

.pl-110 {
  padding-left: 110px; }

.pl-120 {
  padding-left: 120px; }

.pl-125 {
  padding-left: 125px; }

.pl-130 {
  padding-left: 130px; }

.pl-140 {
  padding-left: 140px; }

.pl-150 {
  padding-left: 150px; }

.pl-155 {
  padding-left: 155px; }

.pl-190 {
  padding-left: 190px; }

.pl-195 {
  padding-left: 195px; }

/*************************** Page section padding ****************************/
.ptb-0 {
  padding: 0; }

.ptb-10 {
  padding: 10px 0; }

.ptb-15 {
  padding: 15px 0; }

.ptb-20 {
  padding: 20px 0; }

.ptb-30 {
  padding: 30px 0; }

.ptb-32 {
  padding: 32px 0; }

.ptb-40 {
  padding: 40px 0; }

.ptb-50 {
  padding: 50px 0; }

.ptb-60 {
  padding: 60px 0; }

.ptb-70 {
  padding: 70px 0; }

.ptb-80 {
  padding: 80px 0; }

.ptb-90 {
  padding: 90px 0; }

.ptb-100 {
  padding: 100px 0; }

.ptb-110 {
  padding: 110px 0; }

.ptb-120 {
  padding: 120px 0; }

.ptb-130 {
  padding: 130px 0; }

.ptb-135 {
  padding: 135px 0; }

.ptb-140 {
  padding: 140px 0; }

.ptb-150 {
  padding: 150px 0; }

.ptb-160 {
  padding: 160px 0; }

.ptb-170 {
  padding: 170px 0; }

.ptb-177 {
  padding: 177px 0; }

.ptb-180 {
  padding: 180px 0; }

.ptb-190 {
  padding: 190px 0; }

.ptb-200 {
  padding: 200px 0; }

.ptb-210 {
  padding: 210px 0; }

.ptb-220 {
  padding: 220px 0; }

.ptb-250 {
  padding: 250px 0; }

.ptb-290 {
  padding: 290px 0; }

.ptb-310 {
  padding: 310px 0; }

/*************************** Page section margin ****************************/
.mtb-0 {
  margin: 0; }

.mtb-10 {
  margin: 10px 0; }

.mtb-15 {
  margin: 15px 0; }

.mtb-20 {
  margin: 20px 0; }

.mtb-30 {
  margin: 30px 0; }

.mtb-40 {
  margin: 40px 0; }

.mtb-50 {
  margin: 50px 0; }

.mtb-60 {
  margin: 60px 0; }

.mtb-70 {
  margin: 70px 0; }

.mtb-80 {
  margin: 80px 0; }

.mtb-90 {
  margin: 90px 0; }

.mtb-100 {
  margin: 100px 0; }

.mtb-110 {
  margin: 110px 0; }

.mtb-120 {
  margin: 120px 0; }

.mtb-130 {
  margin: 130px 0; }

.mtb-140 {
  margin: 140px 0; }

.mtb-150 {
  margin: 150px 0; }

.mtb-290 {
  margin: 290px 0; }

@media only screen and (max-width: 575px) {
  blockquote {
    padding: 15px; } }

@-webkit-keyframes crZoomInOut {
  0%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); } }

@keyframes crZoomInOut {
  0%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); } }

@-webkit-keyframes crZoomInOutInvisible {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@keyframes crZoomInOutInvisible {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@-webkit-keyframes crFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes crFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes crSlideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes crSlideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes crBounceIn {
  20%, 40%, 60%, 80%, from, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes crBounceIn {
  20%, 40%, 60%, 80%, from, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-webkit-keyframes crSlideInRight {
  0% {
    -webkit-transform: translateX(150px);
    transform: translateX(150px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes crSlideInRight {
  0% {
    -webkit-transform: translateX(150px);
    transform: translateX(150px);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes carInOutAnimation {
  0% {
    -webkit-transform: translateX(200%);
    transform: translateX(200%); }
  18%, 80% {
    -webkit-transform: translateX(-5%);
    transform: translateX(-5%); }
  20%, 82% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%); }
  22%, 78% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%); } }

@keyframes carInOutAnimation {
  0% {
    -webkit-transform: translateX(200%);
    transform: translateX(200%); }
  18%, 80% {
    -webkit-transform: translateX(-5%);
    transform: translateX(-5%); }
  20%, 82% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%); }
  22%, 78% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%); } }

@-webkit-keyframes hoFill {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

@keyframes hoFill {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

button, a.btn {
  padding: 0 20px;
  line-height: 38px;
  height: 38px;
  font-size: 14px;
  margin-right: 5px;
  font-weight: 500; }

.btn {
  border-radius: 0;
  cursor: pointer; }
  .btn i {
    margin-right: 5px; }
  .btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0 none;
    border: 0;
    box-shadow: none; }
  .btn.btn-orange {
    background: #ff5722;
    color: #fff; }
  .btn.btn-green {
    background-color: #42b549;
    color: #fff; }
  .btn.btn-blue {
    background: #0096d9;
    color: #fff; }
  .btn.btn-facebook {
    background: #3b5998;
    color: #FFFFFF;
    margin-bottom: 5px;
    font-size: 16px; }
  .btn.btn-google {
    border: 1px solid #e5e5e5;
    font-size: 16px;
    margin-bottom: 5px; }
  .btn.btn-dark {
    background-color: #333333;
    color: #fff !important; }
  .btn.btn-outline-orange {
    background: #FFFFFF;
    color: #ff5722;
    border: 1px solid #ff5722; }
  .btn.btn-outline-green {
    background: #FFFFFF;
    color: #42b549;
    border: 1px solid #42b549; }
  .btn.btn-outline-blue {
    background: #FFFFFF;
    color: #0096d9;
    border: 1px solid #0096d9; }
  .btn.btn-outline-red {
    background: #FFFFFF;
    color: #FE5F55;
    border: 1px solid #FE5F55; }
  .btn.btn-outline-pink {
    background: #FFFFFF;
    color: #ff4081;
    border: 1px solid #ff4081; }
  .btn.btn-outline-dark {
    background: #FFFFFF;
    color: #333333;
    border: 1px solid #333333; }

.form-message {
  margin-bottom: 0;
  text-align: center; }
  .form-message.error {
    margin-top: 20px;
    color: #f80707; }
  .form-message.success {
    margin-top: 20px;
    color: #0d8d2d; }

.modal-content {
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  padding: 20px 30px 20px; }
  .modal-content.login-modal {
    max-width: 450px; }
    .modal-content.login-modal .modal-body {
      padding: 0; }
    .modal-content.login-modal .logo {
      width: 70px;
      height: 70px; }
    .modal-content.login-modal .title {
      font-size: 24px;
      color: #333;
      font-weight: 300; }
  .modal-content .close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer; }

@media (max-width: 600px) {
  .modal-content.login-modal {
    max-width: 410px; } }

.header {
  position: relative;
  z-index: 999;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14); }
  .header .header-top {
    background-color: #f5f5f5;
    padding: 2px 0; }
  .header.is-sticky {
    background: #FFFFFF;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
    -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s; }

.header-top li {
  list-style-type: none;
  margin-left: 25px; }

.header-top-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.header-top-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.header-middle {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 5px 0; }
  .header-middle .header-logo {
    display: inline-block;
    vertical-align: middle; }
    .header-middle .header-logo img {
      max-height: 40px; }

.header-searchbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background: #FFFFFF;
  border-radius: 7px;
  flex-grow: 1;
  height: 40px; }
  .header-searchbox input {
    background: #f8f8f8;
    border: 0;
    padding-right: 50px;
    border: 1px solid #ddd; }
    .header-searchbox input::-webkit-input-placeholder, .header-searchbox input:-moz-placeholder, .header-searchbox input::-moz-placeholder, .header-searchbox input:-ms-input-placeholder {
      color: #b4b4b4; }
  .header-searchbox .btn-search {
    background: #ff5722;
    color: #FFFFFF;
    line-height: 40px;
    height: 40px;
    text-align: center;
    min-width: 70px;
    border: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    font-size: 16px;
    -webkit-transition: all .3s ease-in-out 0s;
    -o-transition: all .3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; }

.header-icons {
  position: relative;
  display: flex;
  align-items: center; }

.header-account {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-left: 20px; }
  .header-account .account-menu {
    color: #42b549; }

.header-carticon {
  display: inline-block;
  position: relative;
  text-align: center;
  background: #FFFFFF;
  color: #454545;
  border-radius: 100%;
  font-size: 24px;
  font-weight: 700;
  height: 48px;
  width: 48px;
  line-height: 1;
  vertical-align: middle;
  border: 1px solid #FFFFFF; }
  .header-carticon i {
    line-height: 48px; }
  .header-carticon span.count {
    position: absolute;
    top: -3px;
    right: -6px;
    display: block;
    overflow: hidden;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    background: #ff5722;
    text-align: center;
    border-radius: 100%; }
  .header-carticon:visited {
    color: #454545; }

.header-cart {
  display: inline-block;
  position: relative; }
  .header-cart .header-minicart {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 9;
    display: none; }
  .header-cart .minicart {
    border-bottom: 2px solid #42b549;
    width: 350px;
    background: #FFFFFF;
    text-align: left;
    padding: 30px 20px 20px;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); }
    .header-cart .minicart .minicart-header {
      max-height: 230px;
      overflow-y: auto; }
    .header-cart .minicart .minicart-product {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 20px; }
    .header-cart .minicart .minicart-productcontent h6 {
      font-weight: 400;
      margin-bottom: 5px; }
      .header-cart .minicart .minicart-productcontent h6 a {
        color: #454545; }
        .header-cart .minicart .minicart-productcontent h6 a:hover {
          color: #42b549; }
    .header-cart .minicart .minicart-productcontent .minicart-productprice {
      color: #42b549;
      font-size: 16px;
      font-weight: 700; }
    .header-cart .minicart .minicart-productimage {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
      flex: 0 0 70px;
      max-width: 70px;
      margin-right: 20px;
      position: relative; }
      .header-cart .minicart .minicart-productimage a {
        display: inline-block; }
      .header-cart .minicart .minicart-productimage img {
        width: 100%; }
      .header-cart .minicart .minicart-productimage .minicart-productquantity {
        position: absolute;
        left: 0;
        top: 0;
        background: #42b549;
        height: 22px;
        width: 27px;
        line-height: 25px;
        text-align: center;
        border-radius: 100px;
        font-size: 12px;
        text-transform: uppercase;
        color: #FFFFFF; }
    .header-cart .minicart .minicart-productcontent {
      padding-right: 10px; }
    .header-cart .minicart .minicart-productclose {
      height: 30px;
      width: auto;
      padding: 0;
      line-height: 1;
      border: none;
      font-size: 18px; }
      .header-cart .minicart .minicart-productclose:hover {
        color: #42b549; }
    .header-cart .minicart .minicart-pricing {
      padding-left: 0;
      padding-top: 20px;
      margin-bottom: 20px;
      list-style: none;
      border-top: 1px solid #e5e5e5; }
      .header-cart .minicart .minicart-pricing li {
        list-style: none;
        display: block;
        color: #7e7e7e;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px; }
        .header-cart .minicart .minicart-pricing li::after {
          content: "";
          clear: both;
          display: table; }
        .header-cart .minicart .minicart-pricing li span {
          color: #42b549;
          float: right; }

.title-category-dropdown::after {
  content: "\f0c9";
  font-family: fontawesome;
  background: #ffffff;
  border: 1px solid #eee;
  display: inline-block;
  color: #42b549;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
  padding: 4px;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle; }

.wrap-category-dropdown {
  background: #fff;
  left: -190px;
  margin: 0;
  margin-top: 10px;
  position: absolute;
  top: 100%;
  width: 220px;
  z-index: 99999;
  display: none;
  padding: 10px; }
  .wrap-category-dropdown ul {
    padding: 0; }
  .wrap-category-dropdown li {
    list-style-type: none; }

.list-category-dropdown {
  margin: 0;
  position: relative; }
  .list-category-dropdown .title {
    font-size: 16px;
    font-weight: bold;
    color: #42b549;
    text-transform: uppercase; }
  .list-category-dropdown li {
    padding: 8px; }
    .list-category-dropdown li a {
      font-size: 14px; }
    .list-category-dropdown li a:hover {
      color: #42b549; }
    .list-category-dropdown li i {
      font-size: 16px;
      margin-right: 8px; }

.cat-mega-menu {
  background: #fff none repeat scroll 0 0;
  left: 100%;
  position: absolute;
  top: -10px;
  border-top: 5px solid #ff5722;
  padding: 20px 30px;
  margin-left: 100px;
  opacity: 0;
  visibility: hidden;
  width: 900px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
  .cat-mega-menu li {
    list-style-type: none; }

.list-category-dropdown > li.has-cat-mega:hover > .cat-mega-menu {
  opacity: 1;
  visibility: visible;
  margin-left: 0; }

.list-category-dropdown > li:hover > a::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 4px;
  z-index: 10; }

.list-cat-mega-menu a {
  color: #666; }
  .list-cat-mega-menu a:hover {
    color: #42b549; }

.list-cat-mega-menu .title-cat-mega-menu {
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 10px;
  padding-bottom: 12px;
  text-transform: uppercase; }

.list-cat-mega-menu ul {
  padding-left: 0; }
  .list-cat-mega-menu ul li {
    color: #ccc;
    list-style: none;
    margin-bottom: 7px;
    padding: 1px; }

/* iPhone 5 & 5S in portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
  .header-carticon {
    right: 10px;
    position: absolute;
    top: -10px;
    right: 20px; }
  .header-searchbox {
    margin: 0 20px;
    margin-top: 10px; } }

/* iPad in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .header-carticon {
    right: 20px; } }

/* Galaxy S5 portrait */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .header-cart {
    right: 10px; }
  .header-searchbox {
    margin: 0 20px;
    margin-top: 10px; } }

@media screen and (device-width: 441px) and (device-height: 731px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .header-cart {
    right: 20px; } }

@media only screen and (max-width: 575px) {
  .container {
    max-width: 100%; } }

@media (max-width: 992px) {
  .header-middle {
    position: fixed;
    width: 100%;
    background: #FFFFFF !important;
    z-index: 999;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14); }
    .header-middle .wrap-category-dropdown {
      background: #fff;
      left: 0;
      margin: 0;
      position: fixed;
      top: 120px;
      width: 100%;
      z-index: 99999;
      display: none;
      padding: 10px 0; }
      .header-middle .wrap-category-dropdown ul.list-category-dropdown li {
        border-bottom: 1px solid #e5e5e5; }
    .header-middle #hamburger-desktop {
      display: none !important; }
    .header-middle .hamburger-mobile-menu {
      display: inline; }
    .header-middle .header {
      position: sticky; }
    .header-middle .category-dropdown {
      position: relative; }
    .header-middle .container {
      padding: 0; }
    .header-middle .header-middle {
      padding: 10px 0; }
    .header-middle .header-logo {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 5px; }
    .header-middle .header-logo img {
      max-height: 35px;
      margin-top: 10px; }
    .header-middle .header-account {
      display: none; }
  .header-cart {
    position: absolute;
    margin-right: 0;
    z-index: 999;
    top: -90px;
    right: 10px; }
    .header-cart .header-carticon {
      position: absolute;
      right: 0; }
    .header-cart .minicart {
      position: fixed;
      left: 0px;
      top: 112px;
      width: 100%;
      /* height: 100vh; */
      margin-left: 0px; } }

.hamburger-mobile-menu {
  position: absolute;
  z-index: 10012;
  /* padding: 10px; */
  font-size: 18px;
  line-height: 38px;
  height: 38px;
  width: 38px;
  text-align: center;
  left: 5px;
  top: 10px;
  color: #42b549;
  display: none;
  border: 1px solid #eee; }
  .hamburger-mobile-menu span {
    color: #42b549; }

.mobile-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
  border: 1px solid #e5e5e5;
  border-width: 0 1px 0 0;
  background-color: #F2F2F2;
  width: 100%;
  max-width: 250px;
  overflow-y: auto;
  visibility: hidden; }
  .mobile-menu-container .header-account {
    min-height: 80px;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center; }
  .mobile-menu-container .nano-pane {
    background: rgba(255, 255, 255, 0);
    font-size: 15px; }
  .mobile-menu-container .menu-list {
    border-right: 1px solid #CCC; }
  .mobile-menu-container .menu-list li a {
    padding-left: 20px; }
  .mobile-menu-container .menu-list {
    margin: 0;
    padding: 0;
    list-style: none; }
    .mobile-menu-container .menu-list li:first-child {
      border-top: 0; }
    .mobile-menu-container .menu-list li {
      display: block;
      padding: 0;
      margin: 0;
      border: 0;
      border-top: 1px solid #fcfcfc;
      border-bottom: 1px solid #e5e5e5;
      position: relative;
      text-decoration: none; }
      .mobile-menu-container .menu-list li.arrow-down:after {
        content: '';
        width: 7px;
        height: 7px;
        position: absolute;
        right: 10px;
        top: 15px;
        border-top: 1px solid #bababa;
        border-left: 1px solid #bababa;
        -webkit-transform: rotate(45deg);
        -webkit-animation: arrow-slide .5s 0s ease both; }
      .mobile-menu-container .menu-list li.init-arrow-down:after {
        content: '';
        width: 7px;
        height: 7px;
        position: absolute;
        right: 10px;
        top: 15px;
        border-right: 1px solid #bababa;
        border-bottom: 1px solid #bababa;
        -webkit-transform: rotate(45deg); }
      .mobile-menu-container .menu-list li.arrow-up:after {
        content: '';
        width: 7px;
        height: 7px;
        position: absolute;
        right: 10px;
        top: 15px;
        border-right: 1px solid #bababa;
        border-bottom: 1px solid #bababa;
        -webkit-transform: rotate(45deg);
        -webkit-animation: arrow-slide1 .5s 0s ease both; }
      .mobile-menu-container .menu-list li.init-arrow-up:after {
        content: '';
        width: 7px;
        height: 7px;
        position: absolute;
        right: 10px;
        top: 15px;
        border-top: 1px solid #bababa;
        border-left: 1px solid #bababa;
        -webkit-transform: rotate(45deg); }
      .mobile-menu-container .menu-list li.active {
        background-color: #fff; }
      .mobile-menu-container .menu-list li > a {
        display: block;
        height: 38px;
        line-height: 36px;
        padding: 0 16px 0 7px;
        background-color: #f9f9f9;
        color: #585858;
        text-shadow: none !important;
        font-size: 13px;
        text-decoration: none; }

@-webkit-keyframes arrow-slide {
  0% { }
  100% {
    -webkit-transform: rotate(225deg);
    z-index: 3; } }

@-webkit-keyframes arrow-slide1 {
  0% { }
  100% {
    -webkit-transform: rotate(225deg);
    z-index: 3; } }
  .mobile-menu-container .gw-open > a {
    outline: 0; }
  .mobile-menu-container .menu-list > li.gw-open {
    border-bottom-color: #e5e5e5; }
    .mobile-menu-container .menu-list > li.gw-open > a {
      background-color: #fafafa;
      color: #1963aa; }
  .mobile-menu-container .menu-list .gw-open > a {
    background-color: #fafafa; }
    .mobile-menu-container .menu-list .gw-open > a:hover, .mobile-menu-container .menu-list .gw-open > a:focus {
      background-color: #fafafa; }
  .mobile-menu-container .menu-mobile-nav .gw-open > a {
    background-color: #eee;
    border-color: #428bca; }
    .mobile-menu-container .menu-mobile-nav .gw-open > a:hover, .mobile-menu-container .menu-mobile-nav .gw-open > a:focus {
      background-color: #eee;
      border-color: #428bca; }
  .mobile-menu-container .menu-list > li.active > a {
    background-color: #fff;
    color: #dd4814;
    font-weight: bold;
    font-size: 13px; }
    .mobile-menu-container .menu-list > li.active > a:hover, .mobile-menu-container .menu-list > li.active > a:focus, .mobile-menu-container .menu-list > li.active > a:active {
      background-color: #fff;
      color: #dd4814;
      font-weight: bold;
      font-size: 13px; }
  .mobile-menu-container .menu-list > li > a {
    margin: 0; }
  .mobile-menu-container .menu-list .menu-mobile-nav-header {
    margin: 0; }
  .mobile-menu-container .menu-list > li.active > a > [class*="icon-"] {
    font-weight: normal; }
  .mobile-menu-container .menu-list > li.active > a:hover:before {
    display: none; }
  .mobile-menu-container .menu-list > li.active:before {
    display: inline-block;
    content: "";
    position: absolute;
    right: -2px;
    top: -1px;
    bottom: 0;
    z-index: 1;
    border: 2px solid #dd4814;
    border-width: 0 2px 0 0; }
  .mobile-menu-container .menu-list li.gw-open > a:after {
    display: none; }
  .mobile-menu-container .menu-list li .gw-submenu {
    overflow: hidden; }
  .mobile-menu-container .menu-list li.active.gw-open > .gw-submenu > li.active.gw-open > a.dropdown-toggle:after {
    display: none; }
  .mobile-menu-container .menu-list li.active.gw-open > .gw-submenu > li.active > a:after {
    display: block; }
  .mobile-menu-container .menu-list li.active > .gw-submenu > li.active > a:after {
    display: none; }
  .mobile-menu-container .menu-list > li a > .gw-arrow {
    display: inline-block;
    width: 14px !important;
    height: 14px;
    line-height: 14px;
    text-shadow: none;
    font-size: 18px;
    position: absolute;
    right: 11px;
    top: 11px;
    padding: 0;
    color: #666; }
  .mobile-menu-container .menu-list > li a:hover > .gw-arrow {
    color: #1963aa; }
  .mobile-menu-container .menu-list > li.active > a > .gw-arrow,
  .mobile-menu-container .menu-list > li.gw-open > a > .gw-arrow {
    color: #1963aa; }
  .mobile-menu-container .menu-list > li > a > [class*="icon-"]:first-child {
    display: inline-block;
    vertical-align: middle;
    min-width: 30px;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    margin-right: 2px; }
  .mobile-menu-container .menu-list > li .gw-submenu {
    font-size: 13px;
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    background-color: #fff;
    border-top: 1px solid #e5e5e5; }
    .mobile-menu-container .menu-list > li .gw-submenu > li {
      margin-left: 0;
      position: relative; }
      .mobile-menu-container .menu-list > li .gw-submenu > li > a {
        display: block;
        position: relative;
        color: #616161;
        padding: 7px 0 9px 43px;
        margin: 0;
        border-top: 1px dotted #e4e4e4;
        font-size: 14px;
        text-decoration: none; }
        .mobile-menu-container .menu-list > li .gw-submenu > li > a:focus {
          text-decoration: none; }
        .mobile-menu-container .menu-list > li .gw-submenu > li > a:hover {
          text-decoration: none;
          color: #dd4814;
          background-color: rgba(25, 25, 50, 0.1); }
      .mobile-menu-container .menu-list > li .gw-submenu > li.active:after {
        display: inline-block;
        content: "";
        position: absolute;
        right: -1px;
        top: -1px;
        bottom: 0;
        z-index: 1;
        border: 2px solid #dd4814; }
      .mobile-menu-container .menu-list > li .gw-submenu > li.active > a {
        color: #dd4814; }
      .mobile-menu-container .menu-list > li .gw-submenu > li a > [class*="icon-"]:first-child {
        display: none;
        font-size: 12px;
        font-weight: normal;
        width: 18px;
        height: auto;
        line-height: 12px;
        text-align: center;
        position: absolute;
        left: 10px;
        top: 11px;
        z-index: 1;
        background-color: #FFF; }
      .mobile-menu-container .menu-list > li .gw-submenu > li.active > a > [class*="icon-"]:first-child,
      .mobile-menu-container .menu-list > li .gw-submenu > li:hover > a > [class*="icon-"]:first-child {
        display: inline-block; }
      .mobile-menu-container .menu-list > li .gw-submenu > li.active > a > [class*="icon-"]:first-child {
        color: #c86139; }
  .mobile-menu-container .menu-list > li > .gw-submenu > li:first-child > a {
    border-top: 0px; }
  .mobile-menu-container .menu-mobile-nav-tabs li[class*=" icon-"],
  .mobile-menu-container .nav-tabs li[class^="icon-"] {
    width: 1.25em;
    display: inline-block;
    text-align: center; }

.owl-carousel .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-carousel .owl-nav {
  margin-top: 10px; }

.owl-carousel .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer; }

.owl-carousel .owl-nav .disabled {
  opacity: .5;
  cursor: default; }

.owl-carousel .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-carousel .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }

.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
  background: #ff5722; }

.slider-item {
  max-width: 100%; }
  .slider-item img {
    max-width: 100%; }

.highlight-slider .slider-item {
  max-width: 992px; }

.products-slider .owl-nav, .categories-slider .owl-nav {
  left: inherit;
  position: absolute;
  text-align: center;
  top: -58px;
  right: 0;
  padding: 0;
  font-size: 21px; }

.products-slider .owl-nav button.owl-prev, .products-slider .owl-nav button.owl-next, .categories-slider .owl-nav button.owl-prev, .categories-slider .owl-nav button.owl-next {
  line-height: 40px; }
  .products-slider .owl-nav button.owl-prev :hover, .products-slider .owl-nav button.owl-next :hover, .categories-slider .owl-nav button.owl-prev :hover, .categories-slider .owl-nav button.owl-next :hover {
    color: #42b549; }

.banner-list-slider .slider-item {
  width: 100%; }

@media (max-width: 992px) {
  .owl-carousel .owl-item img {
    display: block;
    max-width: 992px; } }

@media (max-width: 640px) {
  .owl-carousel .owl-item img {
    display: block;
    max-width: 280px;
    max-height: 250px; } }

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5; }
  .hr-text:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px; }
  .hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 .5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa; }

.oficial-store-area li {
  display: inline-block; }

.oficial-store-area img {
  max-width: 130px;
  max-height: 130px;
  margin: 20px; }

.highlight-area .nav-link {
  border-radius: 0;
  color: #636363;
  outline: 1px solid #eee;
  font-size: 15px;
  font-weight: 600; }
  .highlight-area .nav-link.active {
    background: #42b549; }

.overlay {
  position: fixed;
  display: none;
  height: 100%;
  /* color with alpha transparency */
  background-color: rgba(0, 0, 0, 0.5);
  /* stretch to screen edges */
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  z-index: 99; }

.section {
  padding: 20px;
  background: #FFFFFF;
  margin-top: 20px; }
  .section .section-title h3::after {
    content: "";
    width: 100%;
    left: 0;
    bottom: -1px; }
  .section.category-area {
    max-height: 230px; }

.klevv {
  background: url("../images/klevv.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.dropdown-list {
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  background: #FFFFFF;
  border-bottom: 2px solid #42b549;
  padding: 0 20px;
  min-width: 150px;
  text-align: left;
  -webkit-box-shadow: 0 2px 13.95px 1.05px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 13.95px 1.05px rgba(0, 0, 0, 0.05);
  z-index: 99;
  display: none; }

.dropdown-list li {
  list-style: none;
  display: block; }
  .dropdown-list li:not(:last-child) {
    border-bottom: 1px solid #e5e5e5; }
  .dropdown-list li a {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    padding: 10px 0;
    color: #7e7e7e;
    line-height: 25px;
    font-size: 13px; }
    .dropdown-list li a:hover {
      color: #42b549; }

[class*=" flaticon-"]:after, [class*=" flaticon-"]:before {
  font-size: inherit;
  margin-left: 0; }

[class^=flaticon-]:after, [class^=flaticon-]:before {
  font-size: inherit;
  margin-left: 0; }

@media (min-width: 992px) {
  .col-2-of-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 25%; } }

.tab-content {
  width: 100%; }
  .tab-content .tab-pane {
    display: block;
    height: 0;
    max-width: 100%;
    visibility: hidden;
    overflow: hidden;
    opacity: 0; }

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ho-button {
    font-size: 14px;
    padding: 5px 15px 8px;
    border-radius: 5px; }
    .ho-button:link {
      font-size: 14px;
      padding: 5px 15px 8px;
      border-radius: 5px; }
    .ho-button i, .ho-button:link i {
      font-size: 17px; } }

@media only screen and (max-width: 767px) {
  .ho-button {
    font-size: 14px;
    padding: 6px 15px;
    border-radius: 5px; }
    .ho-button:link {
      font-size: 14px;
      padding: 6px 15px;
      border-radius: 5px; }
    .ho-button i, .ho-button:link i {
      font-size: 17px; } }

.ho-readmore {
  color: #454545; }
  .ho-readmore:hover {
    color: #42b549; }

.section-title {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 8px;
  display: block; }
  .section-title::after {
    content: "";
    clear: both;
    display: table; }
  .section-title h3 {
    font-weight: 700;
    display: inline-block;
    margin-bottom: 0;
    line-height: 40px;
    position: relative; }
    .section-title h3::after {
      position: absolute;
      background: #42b549;
      height: 2px; }
  .section-title .nav li a {
    position: relative;
    background: 0 0;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 2;
    cursor: pointer;
    vertical-align: middle; }

.section-title .nav {
  float: right;
  margin-right: 80px; }
  .section-title .nav li {
    display: inline-block;
    margin: 0 10px; }
    .section-title .nav li:first-child {
      margin-left: 0; }
    .section-title .nav li:last-child {
      margin-right: 0; }
    .section-title .nav li a {
      height: 40px;
      display: inline-block;
      line-height: 40px;
      color: #ababab;
      font-size: 14px;
      padding: 0;
      -webkit-transition: all .3s ease;
      -o-transition: all .3s ease;
      transition: all 0.3s ease; }
      .section-title .nav li a.active, .section-title .nav li a:hover {
        color: #454545; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title .nav {
    margin-right: 60px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title .nav {
    float: none;
    margin-right: 50px; } }

@media only screen and (max-width: 767px) {
  .section-title .nav {
    float: none;
    margin-right: 50px; } }

@media only screen and (max-width: 575px) {
  .section-title .nav {
    padding-top: 15px;
    padding-bottom: 10px; }
    .section-title .nav li {
      margin-left: 0;
      margin-right: 15px; }
      .section-title .nav li a {
        height: auto;
        line-height: 24px; } }

.small-title {
  padding-bottom: 10px; }

.socialicons ul {
  padding-left: 0;
  margin-bottom: 0;
  display: inline-block;
  list-style: none;
  font-size: 0; }
  .socialicons ul li {
    display: inline-block;
    list-style: none;
    font-size: 18px;
    margin: 0 10px; }
    .socialicons ul li:first-child {
      margin-left: 0; }
    .socialicons ul li:last-child {
      margin-right: 0; }
    .socialicons ul li a {
      display: inline-block;
      color: #454545; }
      .socialicons ul li a:hover {
        color: #42b549; }

.socialicons-radial ul li {
  margin: 0 7px; }
  .socialicons-radial ul li a {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #454545;
    background: #FFFFFF;
    font-size: 18px;
    padding: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid #e5e5e5;
    border-radius: 100%;
    line-height: 1; }
    .socialicons-radial ul li a:hover {
      background: #42b549;
      border-color: #42b549;
      color: #FFFFFF; }

@media only screen and (max-width: 767px) {
  .socialicons-radial ul li {
    margin: 0 4px; }
    .socialicons-radial ul li a {
      height: 38px;
      width: 38px;
      font-size: 15px; }
      .socialicons-radial ul li a i {
        line-height: 38px; } }

.breadcrumb {
  background: none;
  padding: 0; }

.breadcrumb ul {
  display: inline-block;
  padding: 5px 0;
  margin-bottom: 0;
  font-size: 0; }
  .breadcrumb ul li {
    color: #454545;
    display: inline-block;
    padding: 8px 0;
    font-size: 14px; }
    .breadcrumb ul li::after {
      content: "\f142";
      font-family: "Material Design Icons";
      color: #7e7e7e;
      margin: 0 5px; }
    .breadcrumb ul li:last-child::after {
      display: none; }

.breadcrumb ul li a {
  color: #7e7e7e; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox; }

.pagination {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #e5e5e5; }
  .pagination p {
    padding-bottom: 10px;
    margin-bottom: 0;
    color: #454545; }
  .pagination ul li {
    list-style: none;
    display: inline-block; }
    .pagination ul li a {
      font-weight: 400;
      color: #FFFFFF;
      padding: 5px 10px;
      background: #ccc;
      border-radius: 2px;
      font-size: 14px;
      display: inline-block; }
      .pagination ul li a i {
        font-size: 12px; }
    .pagination ul li.active a, .pagination ul li:hover a {
      background: #42b549; }

.product {
  padding: 15px;
  -webkit-transition: all .3s ease-in-out 0s;
  -o-transition: all .3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }
  .product .product-link {
    margin-top: 5px; }
    .product .product-link img {
      max-width: 30px;
      max-height: 30px;
      margin-right: 5px; }

.product-actionbox, .product-flags {
  padding-left: 0;
  left: 0;
  list-style: none; }

.product-thumb {
  position: relative;
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden; }
  .product-thumb::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    transition: all 0.4s ease-out 0s; }

.product:hover .product-thumb::before {
  opacity: 1;
  visibility: visible;
  z-index: 2; }

.product:hover .product-thumb .product-backimage {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05); }

.product-image {
  position: relative;
  z-index: 1; }

.product-actionbox {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 5;
  margin-bottom: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0; }
  .product-actionbox li {
    list-style: none;
    display: inline-block;
    margin: 0 5px;
    font-size: 18px; }
    .product-actionbox li:first-child {
      margin-left: 0; }
    .product-actionbox li:last-child {
      margin-right: 0; }
    .product-actionbox li a {
      display: inline-block;
      background: #ededed;
      color: #454545;
      padding: 0;
      border: none;
      height: 45px;
      width: 45px;
      font-size: 18px;
      cursor: pointer;
      text-transform: capitalize;
      border-radius: 1000px;
      -webkit-transition: all .3s ease-in-out 0s;
      -o-transition: all .3s ease-in-out 0s;
      transition: all .3s ease-in-out 0s;
      -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
      transform: scale(0.5);
      opacity: 0;
      text-align: center; }
      .product-actionbox li a.is-active, .product-actionbox li a:hover {
        background: #42b549;
        color: #FFFFFF; }
      .product-actionbox li a i {
        line-height: 45px; }

.product-countdown {
  text-align: center; }

.product-content {
  padding-top: 15px; }

.product-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  height: 65px;
  max-height: 65px;
  margin-bottom: 5px; }
  .product-title a {
    color: #454545; }
    .product-title a:hover {
      color: #42b549; }

.product-flags {
  position: absolute;
  top: 0;
  width: 100%;
  margin-bottom: 0;
  z-index: 1; }
  .product-flags li {
    position: absolute;
    left: 0;
    display: inline-block;
    background: #3fcc8d;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 0 5px;
    min-width: 55px;
    text-align: center;
    line-height: 25px;
    height: 25px;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase; }
  .product-flags .flag-discount {
    background: #d31129;
    left: auto;
    right: 0; }

@media only screen and (max-width: 767px) {
  .product-thumb::before {
    display: none; }
  .product-actionbox {
    position: relative;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    margin-top: 15px; }
    .product-actionbox li a {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); } }

@media only screen and (max-width: 575px) {
  .product-thumb::before {
    display: none; }
  .product-actionbox {
    position: relative;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    margin-top: 15px; }
    .product-actionbox li a {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); } }

.product-horizontal .product-actionbox li a i {
  line-height: 40px; }

.product-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  padding: 5px;
  border-bottom: 1px solid #e5e5e5;
  width: 100%; }
  .product-horizontal .product-title {
    max-height: 30px;
    height: 30px; }
  .product-horizontal .product-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    max-width: 100px;
    margin-right: 15px; }
    .product-horizontal .product-image .btn {
      padding: 6px 15px; }
  .product-horizontal .product-content {
    padding: 0; }
  .product-horizontal .product-actionbox {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    top: auto;
    bottom: 0;
    right: auto;
    left: calc(100% + 15px);
    text-align: left; }
    .product-horizontal .product-actionbox li a {
      text-align: center;
      height: 40px;
      width: 40px; }
  .product-horizontal:hover {
    -webkit-box-shadow: none;
    box-shadow: none; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-horizontal .product-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65px;
    flex: 0 0 65px;
    max-width: 65px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-horizontal .product-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65px;
    flex: 0 0 65px;
    max-width: 65px; } }

.rattingbox {
  display: block; }
  .rattingbox span {
    color: #7e7e7e;
    font-size: 14px;
    display: inline-block; }
    .rattingbox span.active {
      color: #fc3; }

.pricebox .oldprice {
  color: #ababab;
  margin-right: 5px;
  display: inline-block; }

.pricebox .price {
  display: inline-block;
  font-weight: 700;
  font-size: 17px;
  color: #42b549; }

.herobanner-single {
  position: relative;
  vertical-align: middle;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 824px; }
  .herobanner-single img {
    width: 100%; }

.herobanner-content {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) translateZ(0);
  transform: translateY(-50%) translateZ(0);
  padding: 15px 45px;
  max-width: 500px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }
  .herobanner-content h4 {
    letter-spacing: 6px;
    color: #ababab;
    font-weight: 700; }
  .herobanner-content h1 {
    font-size: 52px;
    font-weight: 500;
    line-height: 1.1; }
    .herobanner-content h1 span {
      color: #42b549;
      display: inline-block; }

.imgbanner a, .nice-select {
  display: block; }

.herobanner-content p {
  font-size: 18px;
  line-height: 27px;
  color: #7e7e7e; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .herobanner-content {
    padding: 30px;
    max-width: 380px; }
    .herobanner-content h4 {
      margin-bottom: 8px;
      letter-spacing: 0; }
    .herobanner-content h1 {
      font-size: 32px;
      margin-bottom: 8px; }
    .herobanner-content p {
      font-size: 14px;
      line-height: 24px; }
  .herobanner.slider-dots .slick-dots {
    bottom: 15px; } }

@media only screen and (max-width: 767px) {
  .herobanner-content {
    padding: 30px;
    max-width: 380px; }
    .herobanner-content h4 {
      margin-bottom: 8px;
      letter-spacing: 0; }
    .herobanner-content h1 {
      font-size: 32px;
      margin-bottom: 8px; }
    .herobanner-content p {
      font-size: 14px;
      line-height: 24px; }
  .herobanner.slider-dots .slick-dots {
    bottom: 15px; } }

.imgbanner {
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .imgbanner::after, .imgbanner::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    left: 0;
    top: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 1;
    -webkit-transition: all .5s ease-in-out 0s;
    -o-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s;
    z-index: 2;
    pointer-events: none; }
  .imgbanner img {
    -webkit-transition: all .5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s; }

.quickmodal {
  -webkit-transition: all .5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s; }

.imgbanner::before {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.imgbanner:after {
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

.imgbanner img {
  width: 100%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out 0s; }

.imgbanner:hover::after, .imgbanner:hover::before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 0;
  z-index: -1; }

.imgbanner:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2); }

.imgbanner-2::after, .imgbanner-2::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center; }

.imgbanner-2:hover::after {
  opacity: 1;
  z-index: 1; }

.imgbanner-2:hover img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08); }

.nice-select {
  width: 100%;
  border-radius: 0;
  float: none;
  background-color: transparent;
  border: 1px solid #e5e5e5;
  padding: 0 15px; }
  .nice-select::after {
    content: "\f3d0";
    font-family: "Ionicons";
    position: absolute;
    left: auto;
    right: 15px;
    height: 35px;
    line-height: 35px;
    width: 8px;
    border-radius: 100%;
    display: inline-block;
    font-size: 14px;
    padding: 0;
    border: none;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    top: 7px;
    bottom: auto;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-align: center; }
  .nice-select span.current {
    display: block;
    position: relative;
    color: #7e7e7e;
    letter-spacing: 0;
    font-size: 14px;
    padding: 0; }
  .nice-select .list {
    width: 100%;
    border-radius: 0;
    margin-top: 0;
    max-height: 280px;
    overflow-y: auto;
    z-index: 75; }
  .nice-select .option {
    line-height: 30px;
    min-height: 30px;
    padding-left: 15px;
    padding-right: 15px; }
  .nice-select.open {
    border-color: #42b549; }
    .nice-select.open::after {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
  .nice-select:active, .nice-select:focus {
    border-color: #42b549; }

.featurebox {
  position: relative;
  padding-left: 70px;
  padding-top: 20px;
  padding-bottom: 15px;
  max-width: 320px;
  vertical-align: center;
  display: flex; }
  .featurebox i {
    position: absolute;
    left: 0;
    top: 17px;
    font-size: 40px;
    display: inline-block;
    height: auto;
    height: 70px;
    vertical-align: middle;
    color: #aaa; }
  .featurebox h5 {
    vertical-align: middle; }

.featurebox i[class*=" flaticon-"]:after, .featurebox i[class*=" flaticon-"]:before {
  vertical-align: text-bottom; }

.featurebox i[class^=flaticon-]:after, .featurebox i[class^=flaticon-]:before {
  vertical-align: text-bottom; }

.featurebox h5 {
  margin-bottom: 5px; }

.featurebox p {
  font-size: 13px;
  line-height: 23px;
  margin-bottom: 0; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .featurebox {
    max-width: 98%; } }

.newsletter-form, .slider-dots {
  position: relative; }

.brand-area {
  padding: 20px 0; }
  .brand-area ul {
    margin: 0; }
  .brand-area li {
    width: 12.5%;
    outline: 1px solid #eee;
    padding: 0;
    list-style-type: none;
    display: inline-block; }

@media only screen and (max-width: 767px) {
  .countdown-pack {
    margin: 0 3px;
    min-width: 48px;
    min-height: 48px; } }

.category {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-top: 30px; }

.category-thumb {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  max-width: 120px;
  width: 120px;
  height: 120px;
  border-radius: 1000px;
  overflow: hidden;
  margin-right: 15px;
  display: block; }
  .category-thumb img {
    width: 100%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition: all 0.3s ease-in-out 0s; }

.category-title {
  margin-bottom: 5px; }

.category-productcounter {
  display: block;
  color: #ababab;
  font-style: italic;
  margin-bottom: 5px; }

.category-productlink {
  color: #42b549;
  display: block;
  line-height: 20px;
  border-bottom: 1px solid transparent; }
  .category-productlink i {
    vertical-align: middle;
    font-size: 16px;
    padding-left: 5px; }

.category:hover .category-thumb img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-thumb {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    max-width: 90px;
    width: 90px;
    height: 90px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-thumb {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    max-width: 90px;
    width: 90px;
    height: 90px; } }

.category-wrapper {
  margin-top: -7px;
  width: 100%; }

form .single-input label, .sn-progress .progress h6 {
  font-family: Roboto,sans-serif;
  font-weight: 400; }

.video-item {
  padding: 15px;
  -webkit-transition: all .5s ease-in 0s;
  -o-transition: all .5s ease-in 0s;
  transition: all 0.5s ease-in 0s; }

.blogitem {
  padding: 15px;
  -webkit-transition: all .5s ease-in 0s;
  -o-transition: all .5s ease-in 0s;
  transition: all 0.5s ease-in 0s; }

.blogitem-thumb {
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .blogitem-thumb::after, .blogitem-thumb::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    left: 0;
    top: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 1;
    -webkit-transition: all .5s ease-in 0s;
    -o-transition: all .5s ease-in 0s;
    transition: all .5s ease-in 0s;
    z-index: 2;
    pointer-events: none; }

.blogitem2-image img, .team-member {
  -webkit-transition: all .3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s; }

.blogitem-thumb::before {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.blogitem-thumb:after {
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

.blogitem-thumb a {
  display: block; }

.blogitem-thumb img {
  width: 100%; }

.blogitem-thumb:hover::after, .blogitem-thumb:hover::before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 0;
  z-index: -1; }

.blogitem-content {
  text-align: center;
  padding-top: 30px; }

.blogitem-title {
  font-weight: 400;
  margin-bottom: 30px; }
  .blogitem-title a {
    color: #454545; }
    .blogitem-title a:hover {
      color: #42b549; }

.blogitem-meta {
  border-top: 1px solid rgba(229, 229, 229, 0.5);
  border-bottom: 1px solid rgba(229, 229, 229, 0.5);
  padding: 10px 0; }
  .blogitem-meta span {
    font-size: 13px;
    color: #454545;
    display: inline-block; }
    .blogitem-meta span:not(:last-child) {
      margin-right: 20px; }
    .blogitem-meta span i {
      margin-right: 5px;
      font-size: 16px; }
    .blogitem-meta span a {
      color: #454545; }
      .blogitem-meta span a:hover {
        color: #42b549; }

.blogitem:hover {
  -webkit-box-shadow: 0 2px 13.95px 1.05px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 13.95px 1.05px rgba(0, 0, 0, 0.05); }

.blogs-wrapper {
  margin-top: -30px; }

.blogitem2 {
  margin-top: 30px; }

.blogitem2-image {
  position: relative;
  overflow: hidden; }
  .blogitem2-image a {
    display: block; }
  .blogitem2-image img {
    transition: all .3s ease-in-out 0s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }

.blogitem2-content {
  padding-top: 30px; }
  .blogitem2-content h2, .blogitem2-content h4 {
    margin-bottom: 7px; }
  .blogitem2-content h2 a, .blogitem2-content h4 a {
    color: #454545; }
  .blogitem2-content h2 a:hover, .blogitem2-content h4 a:hover {
    color: #42b549; }
  .blogitem2-content p {
    font-size: 15px; }

.blogitem2-meta {
  margin-bottom: 15px; }
  .blogitem2-meta span {
    color: #7e7e7e; }
    .blogitem2-meta span:after {
      content: "|";
      padding: 0 5px; }
    .blogitem2-meta span:last-child::after {
      display: none; }
    .blogitem2-meta span a {
      color: #454545; }
      .blogitem2-meta span a:hover {
        color: #42b549; }

.blogitem2:hover .blogitem2-image img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2); }

.commentlist > h6 {
  font-size: 16px;
  margin-bottom: 25px; }

.single-comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px; }
  .single-comment .single-comment-content p, .single-comment:last-child {
    margin-bottom: 0; }
  .single-comment .single-comment-thumb {
    height: 60px;
    width: 60px;
    overflow: hidden;
    border-radius: 100px;
    margin-right: 25px;
    border: 1px solid #d5d5d5; }
  .single-comment .single-comment-content {
    -ms-flex-negative: 100;
    flex-shrink: 100;
    border: 1px solid #d5d5d5;
    padding: 25px;
    position: relative;
    border-radius: 3px; }
    .single-comment .single-comment-content::before {
      content: "";
      position: absolute;
      left: -6px;
      right: auto;
      top: 10px;
      height: 10px;
      width: 10px;
      background: #FFFFFF;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border-left: 1px solid #d5d5d5;
      border-bottom: 1px solid #d5d5d5; }
  .single-comment .single-comment-content-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .single-comment .single-comment-content-top h6 {
      padding-right: 30px; }
      .single-comment .single-comment-content-top h6 a {
        color: #454545; }
        .single-comment .single-comment-content-top h6 a:hover {
          color: #42b549; }
    .single-comment .single-comment-content-top .ratting-box {
      margin-bottom: 15px;
      -ms-flex-item-align: start;
      align-self: flex-start; }
    .single-comment .single-comment-content-top .reply-button {
      background: #575757;
      color: #FFFFFF;
      padding: 2px 10px;
      -ms-flex-item-align: start;
      align-self: flex-start;
      border-radius: 3px;
      font-size: 13px;
      margin-bottom: 15px; }
      .single-comment .single-comment-content-top .reply-button:hover {
        background: #42b549; }
  .single-comment.single-comment-reply {
    margin-left: 30px; }

@media only screen and (max-width: 575px) {
  .single-comment {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .single-comment .single-comment-content {
      margin-top: 20px;
      padding: 15px; }
      .single-comment .single-comment-content::before {
        left: 20px;
        top: -6px;
        border: none;
        border-left: 1px solid #d5d5d5;
        border-top: 1px solid #d5d5d5; } }

.commentbox > h6 {
  font-size: 16px;
  margin-bottom: 25px; }

.sn-progress-wrap {
  margin-top: -30px; }

.sn-progress {
  position: relative;
  margin-left: 30px;
  padding: 15px 0;
  margin-top: 30px; }
  .sn-progress .progress-bar-count {
    position: absolute;
    display: block;
    background: #ff5722;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    left: 0;
    top: 50%;
    line-height: 60px;
    margin-top: -30px;
    margin-left: -30px;
    text-align: center;
    font-size: 14px;
    color: #454545;
    z-index: 1; }
  .sn-progress .progress {
    background: #f2f2f2;
    height: 30px;
    line-height: 30px;
    border-radius: 0; }
    .sn-progress .progress h6 {
      color: #FFFFFF;
      font-size: 14px;
      margin-bottom: 0;
      padding-left: 45px; }
  .sn-progress .progress-bar {
    background: #42b549;
    text-align: left;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 1s ease-out 0s;
    -o-transition: all 1s ease-out 0s;
    transition: all 1s ease-out 0s; }
    .sn-progress .progress-bar.fill {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); }

.team-member {
  background: #f5f5f5;
  padding-top: 6px;
  margin-top: 30px;
  transition: all 0.3s ease-in-out 0s; }
  .team-member .team-member-content h5, .team-member .team-member-content p {
    -webkit-transition: all .3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s; }
  .team-member img {
    width: 100%; }
  .team-member .team-member-content {
    text-align: center;
    padding: 15px; }
    .team-member .team-member-content h5 {
      font-weight: 400;
      color: #454545;
      font-size: 16px;
      margin-bottom: 5px;
      transition: all 0.3s ease-in-out 0s; }
    .team-member .team-member-content p {
      margin-bottom: 0;
      letter-spacing: 5px;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out 0s; }
  .team-member:hover {
    background: #42b549; }
    .team-member:hover h5, .team-member:hover p {
      color: #FFFFFF; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-member {
    margin-left: 0;
    margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .catmenu-triggeru {
    margin-top: 20px; }
  .ho-form .single-input.single-input-half {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .team-member {
    margin-left: 0;
    margin-right: 0; } }

.shop-widgets .widget-title {
  font-size: 18px;
  font-weight: 700;
  color: #454545;
  position: relative;
  display: block;
  text-transform: uppercase; }

.shop-widgets .single-widget ul {
  padding-left: 0;
  margin-bottom: 0; }
  .shop-widgets .single-widget ul li {
    list-style: none;
    display: block; }

.shop-widgets .shop-widgetbox {
  border: 1px solid #e5e5e5; }
  .shop-widgets .shop-widgetbox .single-widget {
    padding: 20px;
    border-top: 1px solid #e5e5e5; }
    .shop-widgets .shop-widgetbox .single-widget:first-child {
      border-top: 0; }

.shop-widgets .widget-categories {
  border: 1px solid #e5e5e5;
  padding: 20px; }
  .shop-widgets .widget-categories ul li {
    padding: 8px 0;
    border-top: 1px solid #e5e5e5; }
    .shop-widgets .widget-categories ul li:first-child {
      border-top: none; }
    .shop-widgets .widget-categories ul li:last-child {
      padding-bottom: 0; }
    .shop-widgets .widget-categories ul li a {
      text-transform: uppercase;
      color: #7e7e7e;
      font-weight: 600;
      display: block; }
      .shop-widgets .widget-categories ul li a::after {
        content: "";
        clear: both;
        display: table; }
      .shop-widgets .widget-categories ul li a:hover {
        color: #42b549; }
      .shop-widgets .widget-categories ul li a span {
        float: right;
        background: #ff5722;
        padding: 0 10px;
        border-radius: 2px;
        color: #FFFFFF;
        font-size: 12px; }

.shop-widgets .widget-color ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .shop-widgets .widget-color ul li {
    min-width: 50%; }

.shop-widgets .widget-filters .single-widget-range-price {
  margin-top: 20px;
  font-weight: 600;
  color: #454545; }
  .shop-widgets .widget-filters .single-widget-range-price b {
    color: #7e7e7e; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-member {
    margin-left: 0;
    margin-right: 0; }
  .shop-widgets {
    margin-top: 50px; } }

@media only screen and (max-width: 767px) {
  .shop-widgets {
    margin-top: 50px; } }

.blog-widgets {
  margin-top: -30px; }
  .blog-widgets .widget-title {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
    position: relative;
    margin-bottom: 20px; }
    .blog-widgets .widget-title::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 2px;
      width: 80px;
      background: #42b549; }
  .blog-widgets .single-widget {
    margin-top: 30px; }
  .blog-widgets .widget-search-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .blog-widgets .widget-search-form input[type=search], .blog-widgets .widget-search-form input[type=text] {
      width: calc(100% - 50px); }
    .blog-widgets .widget-search-form input[type=search]::-webkit-input-placeholder, .blog-widgets .widget-search-form input[type=text]::-webkit-input-placeholder, .blog-widgets .widget-search-form input[type=search]:-moz-placeholder, .blog-widgets .widget-search-form input[type=text]:-moz-placeholder, .blog-widgets .widget-search-form input[type=search]::-moz-placeholder, .blog-widgets .widget-search-form input[type=text]::-moz-placeholder, .blog-widgets .widget-search-form input[type=search]:-ms-input-placeholder, .blog-widgets .widget-search-form input[type=text]:-ms-input-placeholder {
      color: #7e7e7e; }
    .blog-widgets .widget-search-form [type=submit] {
      display: inline-block;
      border: 1px solid #42b549;
      background: #42b549;
      color: #FFFFFF;
      font-size: 16px;
      text-align: center; }
      .blog-widgets .widget-search-form [type=submit]:hover {
        background: #ff5722;
        border: 1px solid #ff5722; }
  .blog-widgets .widget-categories ul {
    margin-bottom: 0;
    padding-left: 0; }
    .blog-widgets .widget-categories ul li {
      display: block;
      list-style: none; }
      .blog-widgets .widget-categories ul li:not(:last-child) {
        margin-bottom: 8px; }
      .blog-widgets .widget-categories ul li a {
        display: block;
        color: #454545; }
        .blog-widgets .widget-categories ul li a:hover {
          color: #42b549; }
  .blog-widgets .widget-tags ul {
    padding-left: 0;
    margin-bottom: 0;
    font-size: 0;
    margin-left: -5px;
    margin-top: -5px; }
    .blog-widgets .widget-tags ul li {
      list-style: none;
      display: inline-block;
      font-size: 14px;
      margin-left: 5px;
      margin-top: 5px; }
      .blog-widgets .widget-tags ul li a {
        display: inline-block;
        background: #42b549;
        padding: 6px 15px 5px;
        color: #FFFFFF;
        text-transform: uppercase; }
  .blog-widgets .widget-recentblogs ul {
    padding-left: 0;
    margin-bottom: 0; }
    .blog-widgets .widget-recentblogs ul li {
      list-style: none;
      position: relative;
      padding-left: 100px;
      min-height: 52px; }
      .blog-widgets .widget-recentblogs ul li:not(:last-child) {
        margin-bottom: 15px; }
      .blog-widgets .widget-recentblogs ul li .image {
        width: 80px;
        position: absolute;
        left: 0;
        top: 0; }
      .blog-widgets .widget-recentblogs ul li h6 {
        font-size: 15px;
        margin-bottom: 3px; }
        .blog-widgets .widget-recentblogs ul li h6 a {
          color: #454545; }
          .blog-widgets .widget-recentblogs ul li h6 a:hover {
            color: #42b549; }
      .blog-widgets .widget-recentblogs ul li span a {
        color: #454545; }
        .blog-widgets .widget-recentblogs ul li span a:hover {
          color: #42b549; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-widgets {
    margin-top: 0; } }

@media only screen and (max-width: 767px) {
  .blog-widgets {
    margin-top: 0; } }

.pdetails-largeimages {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07); }

.pdetails-thumbs {
  max-width: 450px;
  margin: 10px auto 0; }
  .pdetails-thumbs .slick-slide {
    margin: 10px; }

.pdetails-singlethumb {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07); }

.pdetails .rattingbox {
  margin-bottom: 10px; }

.pdetails-pricebox, .pdetails-quantity {
  margin-bottom: 20px; }

.pdetails-pricebox .oldprice {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ababab;
  margin-right: 10px; }

.pdetails-pricebox .price {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #42b549; }

.pdetails-pricebox .badge {
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  color: #FFFFFF;
  padding: 0 10px;
  background: #d31129;
  vertical-align: 5px;
  border-radius: 5px;
  margin-left: 10px; }

.pdetails-color ul li + li, .pdetails-size ul li + li, .pdetails-socialshare ul li + li {
  margin-left: 5px; }

.pdetails p {
  margin-top: 20px; }

.pdetails-allinfo, .pdetails-description, .pdetails-moreinfo, .pdetails-quantity, .pdetails-reviews {
  margin-top: 30px; }

.pdetails-quantity .ho-button {
  padding: 6.5px 15px; }
  .pdetails-quantity .ho-button:link {
    padding: 6.5px 15px; }

.details-categories, .pdetails-color, .pdetails-size, .pdetails-socialshare, .pdetails-tags {
  padding: 12px 0; }

.details-categories > span, .pdetails-color > span, .pdetails-size > span, .pdetails-socialshare > span, .pdetails-tags > span {
  display: inline-block;
  margin-right: 20px;
  color: #454545;
  font-weight: 500; }

.details-categories ul, .pdetails-color ul, .pdetails-size ul, .pdetails-socialshare ul, .pdetails-tags ul {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0; }

.details-categories ul li, .pdetails-color ul li, .pdetails-size ul li, .pdetails-socialshare ul li, .pdetails-tags ul li {
  list-style: none;
  display: inline-block; }

.details-categories ul li:last-child::after, .pdetails-color ul li:last-child::after, .pdetails-size ul li:last-child::after {
  display: none; }

.pdetails-socialshare ul li::after, .pdetails-socialshare ul li:last-child::after {
  display: none; }

.pdetails-tags ul li:last-child::after {
  display: none; }

.details-categories ul li::after, .pdetails-color ul li::after, .pdetails-size ul li::after, .pdetails-socialshare ul li::after, .pdetails-tags ul li::after {
  content: ","; }

.details-categories ul li a, .pdetails-color ul li a, .pdetails-size ul li a, .pdetails-socialshare ul li a, .pdetails-tags ul li a {
  color: #7e7e7e;
  font-style: italic; }

.details-categories ul li a:hover, .pdetails-color ul li a:hover, .pdetails-size ul li a:hover, .pdetails-socialshare ul li a:hover, .pdetails-tags ul li a:hover {
  color: #42b549; }

.pdetails-socialshare ul li a {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 1;
  text-align: center;
  color: #7e7e7e;
  border: 1px solid #c5c5c5;
  border-radius: 100%;
  font-size: 16px; }
  .pdetails-socialshare ul li a i {
    line-height: 33px; }
  .pdetails-socialshare ul li a:hover {
    color: #FFFFFF;
    border-color: #42b549;
    background: #42b549; }

.pdetails-color ul li::after, .pdetails-size ul li::after {
  display: none; }

.pdetails-color ul li span, .pdetails-size ul li span {
  display: inline-block;
  height: 18px;
  width: 18px;
  background: #42b549;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
  position: relative;
  cursor: pointer; }

.pdetails-color ul li span::before, .pdetails-size ul li span::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .3s ease-in-out 0s;
  -o-transition: all .3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.pdetails-moreinfo a, .pdetails-slidersingleimage {
  border: 1px solid #e5e5e5; }

.pdetails-color ul li.checked span::before, .pdetails-size ul li.checked span::before {
  visibility: visible;
  opacity: 1; }

.pdetails-color ul li.red span, .pdetails-size ul li.red span {
  background: #d858b1; }

.pdetails-color ul li.green span, .pdetails-size ul li.green span {
  background: #5834fb; }

.pdetails-color ul li.blue span, .pdetails-size ul li.blue span {
  background: #b1b1b1; }

.pdetails-color ul li.black span {
  background: #000; }

.pdetails-size ul li.black span {
  background: #000; }

.pdetails-size ul li span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 24px;
  background: 0 0;
  font-size: 10px; }

.pdetails-allinfotab {
  background: #f4f4f4;
  padding: 15px 0; }
  .pdetails-allinfotab li {
    display: inline-block; }
    .pdetails-allinfotab li a {
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      color: #7e7e7e;
      font-weight: 700;
      font-size: 16px;
      padding: 3px 25px; }
      .pdetails-allinfotab li a.active {
        color: #454545; }

.pdetails-moreinfo b {
  color: #454545; }

.pdetails-description {
  line-height: 30px; }
  .pdetails-description ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .pdetails-description ul li {
      list-style: none; }

.pdetails-moreinfo a {
  display: inline-block;
  margin-bottom: 15px; }

.pdetails-moreinfo p {
  margin-bottom: 0; }

.pdetails-affiliatebox {
  margin-top: 28px;
  margin-bottom: 5px; }
  .pdetails-affiliatebox .ho-button {
    margin-right: 10px;
    padding: 9px 20px;
    vertical-align: top;
    margin-bottom: 15px; }
    .pdetails-affiliatebox .ho-button:link {
      margin-right: 10px;
      padding: 9px 20px;
      vertical-align: top;
      margin-bottom: 15px; }
  .pdetails-affiliatebox .product-actionbox {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    position: relative;
    display: inline-block;
    width: auto;
    margin-top: 0;
    margin-bottom: 15px; }

.pdetails-images-vertical .pdetails-images::after, .pdetails-imagesgallery::after {
  content: "";
  clear: both;
  display: table; }

.pdetails-affiliatebox .product-actionbox li a {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.pdetails-imagesgallery {
  margin-left: -15px;
  margin-top: -15px; }
  .pdetails-imagesgallery .pdetails-singleimage {
    margin-left: 15px;
    margin-top: 15px;
    max-width: calc(50% - 15px);
    float: left;
    border: 1px solid #e5e5e5; }

.pdetails-sliderimages {
  margin-left: -15px;
  margin-right: -15px; }
  .pdetails-sliderimages .slick-slide {
    padding: 0 15px; }
  .pdetails-sliderimages .slider-navigation-arrow {
    visibility: hidden;
    opacity: 0; }
  .pdetails-sliderimages:hover .slider-navigation-arrow {
    visibility: visible;
    opacity: 1; }

.pdetails-groupproduct {
  margin-top: 25px; }
  .pdetails-groupproduct .table, .pdetails-groupproduct .table-bordered {
    border-color: #e5e5e5;
    margin-bottom: 0; }
  .pdetails-groupproduct .table td, .pdetails-groupproduct .table tr {
    text-align: center;
    vertical-align: middle; }
  .pdetails-groupproduct .table-bordered td, .pdetails-groupproduct .table-bordered tr {
    text-align: center;
    vertical-align: middle; }
  .pdetails-groupproduct .table .product-quantity, .pdetails-groupproduct .table-bordered .product-quantity {
    min-width: 145px; }
  .pdetails-groupproduct .table .quantity-select, .pdetails-groupproduct .table-bordered .quantity-select {
    margin-right: 0;
    margin-left: 10px; }
  .pdetails-groupproduct .table .pro-title, .pdetails-groupproduct .table-bordered .pro-title {
    font-weight: 700;
    color: #454545;
    min-width: 200px; }
  .pdetails-groupproduct .table .pro-price, .pdetails-groupproduct .table-bordered .pro-price {
    font-weight: 700; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pdetails-content {
    margin-top: 50px; } }

@media only screen and (max-width: 767px) {
  .pdetails-content {
    margin-top: 30px; }
  .pdetails-sliderimages .slider-navigation-arrow {
    visibility: visible;
    opacity: 1; } }

.pdetails-images-vertical .pdetails-largeimages {
  max-width: 428px;
  float: right; }

.pdetails-images-vertical .pdetails-thumbs {
  float: left;
  max-width: 110px;
  margin-top: -5px;
  margin-left: -5px; }
  .pdetails-images-vertical .pdetails-thumbs .slick-list {
    padding: 0 !important; }
  .pdetails-images-vertical .pdetails-thumbs .slick-slide {
    margin: 5px; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pdetails-images-vertical .pdetails-largeimages {
    max-width: 359px; }
  .pdetails-images-vertical .pdetails-thumbs {
    max-width: 90px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pdetails-images-vertical .pdetails-largeimages {
    max-width: 577px; } }

@media only screen and (max-width: 767px) {
  .pdetails-images-vertical .pdetails-largeimages {
    max-width: 408px; }
  .pdetails-images-vertical .pdetails-thumbs {
    max-width: 100px; } }

@media only screen and (max-width: 575px) {
  .pdetails-images-vertical .pdetails-largeimages {
    max-width: 100%; }
  .pdetails-images-vertical .pdetails-thumbs {
    max-width: 100%;
    margin-left: 0;
    margin-top: 8px; } }

.quantity-select {
  display: inline-block;
  position: relative;
  max-width: 80px;
  vertical-align: middle;
  margin-right: 10px;
  overflow: hidden; }
  .quantity-select input {
    text-align: center;
    padding: 0 33px 0 10px;
    height: 40px; }
  .quantity-select .qtybutton {
    position: absolute;
    height: 20.5px;
    width: 24px;
    line-height: 1;
    cursor: pointer;
    border: 1px solid #e5e5e5;
    right: 0;
    text-align: center;
    z-index: 1;
    font-size: 0; }
    .quantity-select .qtybutton i {
      font-size: 12px;
      line-height: 20px; }
    .quantity-select .qtybutton.inc {
      top: 0;
      bottom: auto; }
    .quantity-select .qtybutton.dec {
      bottom: 0;
      top: auto; }

.quickmodal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 0;
  z-index: -2;
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease-in-out 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }
  .quickmodal.is-visible {
    opacity: 1;
    visibility: visible;
    z-index: 100; }
  .quickmodal .body-overlay {
    background: rgba(22, 21, 21, 0.85);
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0; }

.quickmodal-inside {
  position: relative;
  background: #FFFFFF;
  padding: 30px 15px;
  max-height: 100%;
  overflow-y: auto;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all .5s ease-in-out 0s;
  -o-transition: all .5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s; }

.quickmodal.is-visible .quickmodal-inside {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible; }

.quickmodal-close {
  position: absolute;
  left: auto;
  right: 0;
  top: -10px;
  z-index: 1;
  border: none;
  color: #FFFFFF;
  font-size: 50px;
  padding: 10px; }

.shop-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 5px 15px;
  border: 1px solid #e5e5e5;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.shop-filters-viewmode button {
  border: none;
  padding: 0;
  font-size: 24px;
  margin-right: 15px; }
  .shop-filters-viewmode button.is-active {
    color: #42b549; }

.shop-filters-sortby {
  position: relative; }
  .shop-filters-sortby .select-sortby {
    display: inline-block; }
  .shop-filters-sortby .select-sortby-current {
    height: 35px;
    line-height: 35px;
    border: none;
    position: relative;
    padding-right: 20px;
    padding-left: 10px; }
    .shop-filters-sortby .select-sortby-current::after {
      content: "\f140";
      font-family: "Material Design Icons";
      position: absolute;
      right: 0;
      font-size: 15px;
      top: 0;
      height: 100%; }

.cart-pricing-table, .cart-table .table thead th {
  font-family: Roboto, sans-serif; }

.compare-table .table tbody th, .compare-table .table thead th {
  font-family: Roboto, sans-serif; }

.different-address-form-trigger, .myaccount-tab-trigger li a {
  font-family: Roboto, sans-serif; }

.order-infobox .table tfoot th, .order-infobox .table thead th {
  font-family: Roboto, sans-serif; }

.shop-filters-sortby .select-sortby-list {
  min-width: 200px; }

.shop-filters-viewitemcount {
  font-weight: 700; }

.shop-page-products .product .product-content-description {
  display: none;
  padding-top: 10px; }

.shop-page-products.list-view-active [class*=col-] {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.shop-page-products.list-view-active .product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .shop-page-products.list-view-active .product .product-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%; }
  .shop-page-products.list-view-active .product .product-content {
    padding: 15px 0 55px 15px;
    width: 100%; }
    .shop-page-products.list-view-active .product .product-content .product-content-description {
      display: block; }
  .shop-page-products.list-view-active .product .product-actionbox {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    top: auto;
    bottom: 10px;
    right: auto;
    left: calc(100% + 15px);
    text-align: left;
    width: auto;
    white-space: nowrap; }
    .shop-page-products.list-view-active .product .product-actionbox li a {
      text-align: center;
      height: 40px;
      width: 40px; }
      .shop-page-products.list-view-active .product .product-actionbox li a i {
        line-height: 40px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-page-products.list-view-active .product .product-actionbox li a {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible; } }

@media only screen and (max-width: 767px) {
  .shop-page-products.list-view-active .product .product-actionbox {
    margin-top: 95px; }
    .shop-page-products.list-view-active .product .product-actionbox li a {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      opacity: 1;
      visibility: visible; } }

@media only screen and (max-width: 575px) {
  .shop-page-products.list-view-active .product {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .shop-page-products.list-view-active .product .product-image {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .shop-page-products.list-view-active .product .product-actionbox {
      width: 100%;
      left: 0;
      margin-top: 15px; }
    .shop-page-products.list-view-active .product .product-content {
      text-align: left;
      padding: 0; } }

.cart-table .table td, .cart-table .table th {
  vertical-align: middle;
  text-align: center; }

.cart-table .table thead th {
  border-width: 1px;
  padding: 15px 10px; }

.cart-table .table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02); }

.cart-table .table thead th.cart-column-image {
  min-width: 145px; }

.cart-table .table thead th.cart-column-remove {
  min-width: 130px; }

.cart-table .table thead th.cart-column-quantity {
  min-width: 145px; }

.cart-table .table thead th.cart-column-productname {
  min-width: 200px; }

.cart-table .table thead th.cart-column-price, .cart-table .table thead th.cart-column-total {
  min-width: 130px; }

.cart-table .table thead th.cart-column-addtocart {
  min-width: 160px; }

.cart-table .product-image {
  display: inline-block;
  width: 75px;
  padding: 5px 0; }

.cart-table .product-title {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #7e7e7e; }
  .cart-table .product-title:hover {
    color: #42b549; }

.cart-table .total-price {
  display: inline-block;
  font-weight: 700; }

.cart-table .remove-product {
  color: #7e7e7e;
  display: inline-block;
  width: 26px;
  height: 26px;
  font-size: 20px;
  text-align: center;
  line-height: 18px;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  padding: 0;
  -webkit-transition: all .3s ease-out 0s;
  -o-transition: all .3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .cart-table .remove-product:hover {
    color: #FFFFFF;
    background: #42b549;
    border-color: #42b549; }

.cart-content-coupon, .cart-content-left {
  margin-top: 30px; }

.cart-content-coupon > h6 {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px; }

.cart-content-coupon .coupon-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px; }
  .cart-content-coupon .coupon-form button {
    margin-top: 10px; }
  .cart-content-coupon .coupon-form input {
    margin-top: 10px; }
    .cart-content-coupon .coupon-form input[type=text] {
      width: 170px;
      margin-right: 10px; }

.cart-content-right {
  margin-top: 30px;
  text-align: right; }
  .cart-content-right h2 {
    margin-top: -8px;
    display: inline-block;
    border-bottom: 2px solid #454545;
    margin-bottom: 25px; }

.cart-pricing-table {
  border: none; }
  .cart-pricing-table td, .cart-pricing-table th {
    text-align: right;
    border: none;
    padding: 0; }
  .cart-pricing-table td {
    color: #42b549;
    font-weight: 700; }
  .cart-pricing-table .cart-total {
    font-size: 24px; }
    .cart-pricing-table .cart-total td, .cart-pricing-table .cart-total th {
      padding-top: 10px; }

@media only screen and (max-width: 767px) {
  .cart-content-right {
    text-align: left; }
  .cart-pricing-table td, .cart-pricing-table th {
    text-align: left; } }

.checkout-info {
  position: relative;
  background-color: #f7f6f7;
  color: #515151;
  border-top: 3px solid #ff5722;
  width: auto;
  padding: 15px;
  margin-bottom: 30px; }
  .checkout-info i {
    margin-right: 10px;
    vertical-align: middle; }
  .checkout-info a {
    color: #7e7e7e; }
    .checkout-info a:hover {
      color: #42b549; }

.checkout-info-collapsebox {
  padding-bottom: 30px; }

.checkout-info-coupon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -10px; }
  .checkout-info-coupon button {
    margin-top: 10px; }
  .checkout-info-coupon input {
    margin-top: 10px; }
    .checkout-info-coupon input[type=text] {
      width: 170px;
      margin-right: 10px; }

.billing-info {
  margin-top: 50px; }

.order-infobox {
  background: #f7f7f7;
  padding: 30px 40px 45px; }
  .order-infobox .small-title {
    margin-bottom: 20px; }
  .order-infobox .table {
    color: #454545;
    border-bottom: none;
    min-width: 280px; }
    .order-infobox .table td, .order-infobox .table th {
      vertical-align: middle;
      text-align: center;
      padding: 10px 0; }
    .order-infobox .table tbody td span {
      font-weight: 700; }
    .order-infobox .table thead th {
      border-top: none;
      border-width: 1px; }
    .order-infobox .table tfoot td, .order-infobox .table tfoot th {
      font-weight: 700;
      border-bottom: none; }
    .order-infobox .table tfoot .total-price th {
      font-size: 20px; }
    .order-infobox .table tfoot .total-price td {
      font-size: 30px;
      color: #42b549; }

.different-address {
  margin-top: 40px; }

.different-address-form-trigger {
  font-size: 18px;
  font-weight: 700; }

.different-address-form {
  margin-top: 15px;
  display: none; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-infobox {
    margin-top: 50px; } }

@media only screen and (max-width: 767px) {
  .different-address-form-trigger {
    font-size: 16px; }
  .order-infobox {
    margin-top: 50px; }
    .order-infobox .table tfoot .total-price td {
      font-size: 24px; } }

@media only screen and (max-width: 575px) {
  .order-infobox {
    padding: 20px; } }

.compare-table .table td, .compare-table .table th {
  vertical-align: middle;
  text-align: center; }

.compare-table .table thead th {
  border-width: 1px;
  padding: 15px 10px; }

.compare-table .table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02); }

.compare-table .table tbody th {
  min-width: 200px; }

.compare-table .table tbody td {
  min-width: 250px; }

.compare-pdoduct-image {
  max-width: 200px;
  display: inline-block;
  padding: 20px 0; }
  .compare-pdoduct-image a {
    display: inline-block; }
  .compare-pdoduct-image .ho-button {
    margin-top: 25px; }

h5.compare-product-name {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  color: #555; }
  h5.compare-product-name a {
    color: #555;
    -webkit-transition-property: color;
    -o-transition-property: color;
    transition-property: color; }
    h5.compare-product-name a:hover {
      color: #42b549; }

.myaccount-tab-trigger {
  display: block; }
  .myaccount-tab-trigger li {
    display: block; }
    .myaccount-tab-trigger li + li {
      margin-top: -1px; }
    .myaccount-tab-trigger li a {
      border: 1px solid #444;
      display: block;
      background: #555;
      color: #FFFFFF;
      text-transform: uppercase;
      font-weight: 600;
      padding: 10px 20px; }
      .myaccount-tab-trigger li a.active {
        background: #42b549;
        border-color: #42b549;
        color: #FFFFFF; }
      .myaccount-tab-trigger li a:hover:not(.active) {
        color: #42b549; }

.myaccount-tab-content {
  border: 1px solid #e5e5e5;
  padding: 30px;
  -webkit-transition: all .4s ease-in-out 0s;
  -o-transition: all .4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }

.myaccount-dashboard p {
  margin-bottom: 20px; }
  .myaccount-dashboard p:last-child {
    margin-bottom: 0; }

.myaccount-orders .table {
  margin-bottom: 0; }

.myaccount-dashboard p a {
  color: #7e7e7e; }
  .myaccount-dashboard p a:hover {
    color: #42b549; }

.myaccount-orders .table .account-order-id {
  color: #7e7e7e; }
  .myaccount-orders .table .account-order-id:hover {
    color: #42b549; }

.myaccount-orders .table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02); }

.myaccount-orders .table td, .myaccount-orders .table th {
  vertical-align: middle;
  text-align: center; }

.myaccount-address .small-title {
  margin-bottom: 15px; }

.contact-content {
  padding-right: 80px; }
  .contact-content .single-content {
    border-top: 1px solid #e5e5e5;
    padding: 15px 0 15px 65px;
    position: relative; }
    .contact-content .single-content:last-child {
      padding-bottom: 0; }
    .contact-content .single-content:first-child {
      border-top: none; }
    .contact-content .single-content a {
      color: #7e7e7e; }
      .contact-content .single-content a:hover {
        color: #42b549; }
  .contact-content .single-content-icon {
    font-size: 22px;
    position: absolute;
    left: 0;
    top: 15px;
    border: 1px solid #aaa;
    width: 50px;
    line-height: 1;
    text-align: center;
    height: 50px;
    border-radius: 100px; }

.about-content h5, .blog-details-footer {
  border-bottom: 1px solid #e5e5e5; }

.contact-content .single-content-icon i {
  line-height: 48px; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-content {
    padding-right: 0; } }

@media only screen and (max-width: 575px) {
  .contact-content {
    padding-right: 0; } }

.google-map {
  min-height: 452px; }

.about-image {
  margin-right: 70px; }
  .about-image img {
    width: 100%; }

.about-content {
  background: #f5f5f5;
  padding: 65px 50px;
  margin-left: -150px; }
  .about-content h2 {
    font-size: 60px;
    color: #666;
    margin: 0 0 10px;
    font-weight: 400; }
    .about-content h2 span {
      font-size: 100px;
      font-weight: 700;
      color: #42b549;
      line-height: 1;
      display: block; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content {
    padding: 40px 50px; }
    .about-content h2 {
      font-size: 50px; }
      .about-content h2 span {
        font-size: 75px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .google-map {
    min-height: 380px; }
  .about-image {
    margin-right: 0; }
  .about-content {
    margin-left: 0;
    padding: 40px 50px; }
    .about-content h2 {
      font-size: 50px; }
      .about-content h2 span {
        font-size: 75px; } }

@media only screen and (max-width: 767px) {
  .google-map {
    min-height: 320px; }
  .about-image {
    margin-right: 0; }
  .about-content {
    margin-left: 0;
    padding: 40px 50px; }
    .about-content h2 {
      font-size: 45px; }
      .about-content h2 span {
        font-size: 60px; } }

@media only screen and (max-width: 575px) {
  .about-content {
    padding: 25px 20px; }
    .about-content h2 {
      font-size: 38px; }
      .about-content h2 span {
        font-size: 50px; } }

.blog-details .blogitem2 {
  margin-top: 0; }
  .blog-details .blogitem2:hover .blogitem2-image img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }

.blog-details .blogitem2-content p {
  line-height: 28px; }

.blog-details-footer {
  border-top: 1px solid #e5e5e5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px; }

.blog-details-share, .blog-details-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0; }

.blog-details-share h5, .blog-details-tags h5 {
  margin-bottom: 0;
  margin-right: 10px;
  line-height: 26px; }

.blog-details-share ul, .blog-details-tags ul {
  margin-bottom: 0;
  padding-left: 0; }

.blog-details-share ul li, .blog-details-tags ul li {
  list-style: none;
  display: inline-block; }

.blog-details-share ul li a, .blog-details-tags ul li a {
  display: inline-block;
  color: #7e7e7e;
  line-height: 26px; }

.blog-details-share ul li a:hover {
  color: #42b549; }

.blog-details-tags {
  padding-right: 15px; }
  .blog-details-tags ul li a:hover {
    color: #42b549; }
  .blog-details-tags ul li::after {
    content: ",";
    padding: 0 5px; }
  .blog-details-tags ul li:last-child::after {
    display: none; }

.blog-details-share ul li:not(:last-child) {
  margin-right: 10px; }

.blog-details-share ul li a {
  font-size: 18px; }

.circle {
  border-radius: 50%;
  padding: 15px;
  border: 1px solid black;
  vertical-align: center;
  height: 48px;
  width: 48px;
  margin-right: 15px;
  text-align: center; }

.footer-upper {
  padding: 30px 0;
  border-top: 7px solid #42b549; }

.divider {
  border-bottom: 1px solid #e5e5e5; }

.footer-toparea {
  padding-bottom: 60px; }

.footer-widget {
  margin-top: 30px; }
  .footer-widget ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .footer-widget ul li {
      list-style: none; }

@media (max-width: 995px) {
  .footer-widget {
    margin-left: 10px; } }

.footer-widget-title {
  text-transform: uppercase;
  margin-bottom: 25px;
  display: block;
  font-size: 14px;
  color: #333;
  font-weight: 700;
  margin-top: 20px;
  line-height: 16px; }

.footer-widget.widget-info {
  letter-spacing: 0.4px; }
  .footer-widget.widget-info ul li {
    color: #454545;
    margin-bottom: 10px; }
    .footer-widget.widget-info ul li i {
      color: #42b549;
      padding-right: 10px;
      font-size: 18px;
      vertical-align: middle; }
    .footer-widget.widget-info ul li a {
      color: #42b549; }
      .footer-widget.widget-info ul li a:hover {
        text-decoration: underline; }

.footer-widget.widget-links ul li a {
  display: block;
  margin-bottom: 10px;
  color: #7e7e7e; }
  .footer-widget.widget-links ul li a:hover {
    color: #42b549; }

.footer-widget.widget-customerservice {
  background: #454545;
  text-align: center;
  padding: 35px 20px;
  border-radius: 7px; }
  .footer-widget.widget-customerservice .footer-widget-title {
    color: #FFFFFF;
    margin-bottom: 5px; }
  .footer-widget.widget-customerservice .info {
    padding-bottom: 15px; }
    .footer-widget.widget-customerservice .info h2, .footer-widget.widget-customerservice .info h3, .footer-widget.widget-customerservice .info h4, .footer-widget.widget-customerservice .info h5, .footer-widget.widget-customerservice .info h6 {
      color: #a5a5a5;
      margin-bottom: 5px; }
    .footer-widget.widget-customerservice .info h2 a, .footer-widget.widget-customerservice .info h3 a, .footer-widget.widget-customerservice .info h4 a, .footer-widget.widget-customerservice .info h5 a, .footer-widget.widget-customerservice .info h6 a {
      color: #ff5722; }
  .footer-widget.widget-customerservice .payment {
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
    .footer-widget.widget-customerservice .payment h6 {
      margin-bottom: 15px;
      color: #FFFFFF; }

.footer-copyright {
  border-top: 1px solid #e5e5e5;
  padding: 20px 0; }
  .footer-copyright .copyright {
    margin-bottom: 0; }
    .footer-copyright .copyright a {
      color: #42b549;
      font-weight: 500; }

#scrollUp {
  right: 30px;
  bottom: 45px;
  height: 45px;
  width: 45px;
  line-height: 1;
  text-align: center;
  background: #42b549;
  font-size: 22px;
  border-radius: 100%;
  border: 1px solid #42b549;
  -webkit-animation: crSlideInRight .8s ease-in-out 1 both;
  animation: crSlideInRight .8s ease-in-out 1 both;
  z-index: 1000 !important;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }
  #scrollUp i {
    line-height: 45px; }

@media (max-width: 768px) {
  .banner-ads-area, .brand-area, .header-top {
    display: none; }
  .store-area .imgbanner {
    margin-bottom: 10px; } }
