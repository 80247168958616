button, a.btn{
    padding: 0 20px;
    line-height: 38px;
    height: 38px;
    font-size: 14px;
    margin-right: 5px;
    font-weight: 500;
}

.btn {
    border-radius: 0;
	
    cursor: pointer;
    i{
        margin-right: 5px;
    }
	&:focus, &:active:focus, &.active:focus {
        outline: 0 none;
        border: 0;
        box-shadow:none;
	 }
	 &.btn-orange{
		background: $orange;
		color: #fff;
     }
     
	 
    &.btn-green{
        background-color: #42b549;
        color: #fff;
    }
    &.btn-blue{
        background: #0096d9;
        color: #fff;
    }
    &.btn-facebook{
       
        background: #3b5998;
        color: $white;
        margin-bottom: 5px;
        font-size: 16px;
    }
    &.btn-google{
        
        border: 1px solid #e5e5e5;
        font-size: 16px;
        margin-bottom: 5px;
    }

    &.btn-dark{
		background-color: $dark;
		color: #fff!important;
		
    }
    
    &.btn-outline{
        &-orange{
            background: $white;
            color: $orange;
            border: 1px solid $orange;
        }
        &-green{
            background: $white;
            color: $green;
            border: 1px solid $green;
        }
        &-blue{
            background: $white;
            color: $blue;
            border: 1px solid $blue;
        }
        &-red{
            background: $white;
            color: $red;
            border: 1px solid $red;
        }
        &-pink{
            background: $white;
            color: $pink;
            border: 1px solid $pink;
        }
        
        &-dark{
            background: $white;
            color: $dark;
            border: 1px solid $dark;
            
        }

    }
}